import layoutStorage from "../layoutStorage";
import Core from "../../core/Core";

class MegaMenuItemModel {
  id = null;

  name = null;

  title = null;

  metaTitle = null;

  metaDescription = null;

  slug = null;

  link = null;

  filters = {};

  type = "item";

  segment = null;

  enabled = true;

  constructor(menuItem, forcedType = null) {
    if (menuItem) {
      this.id = +menuItem.id;
      this.name = menuItem[`name_${layoutStorage.currentLanguage}`];
      this.title = menuItem[`text_${layoutStorage.currentLanguage}`];
      this.metaTitle = menuItem[`metaTitle_${layoutStorage.currentLanguage}`];
      this.metaDescription =
        menuItem[`metaDescription_${layoutStorage.currentLanguage}`];
      this.slug = menuItem[`slug_${layoutStorage.currentLanguage}`];
      this.link = Core.routing.createRoute(menuItem.segment, {
        slug: menuItem[`slug_${layoutStorage.currentLanguage}`],
        full: true,
      });
      this.filters = menuItem.filters ? JSON.parse(menuItem.filters) : {};
      this.type = forcedType || menuItem.type;
      this.enabled = menuItem.enabled;
      this.segment = menuItem.segment;
      this.slugId = Core.routing.createRoute(menuItem.segment, {
        slug: menuItem[`slug_${layoutStorage.currentLanguage}`],
        full: false,
      });
      this.texts = menuItem.texts || {};
    }
  }

  onClick = (e, page = 0) => {
    // e.preventDefault();
    // e.stopPropagation();
    // productsListStorage.setFilters(this.filters, page);
  };
}

export default MegaMenuItemModel;
