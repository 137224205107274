import currentOrderStorage from "../orderStorage";
import currentUserStorage from "../currentUserStorage";
import shoppingCartStorage from "../shoppingCartStorage";

class RemoteOrderModel {
  phone = "+380987297998";

  name = "Serhii";

  secondname = "test";

  soname = "Kondrashov";

  email = "garphild28@gmail.com";

  paymentType = "5";

  deliveryType = 2;

  deliveryRegion = "";

  deliveryArea = "Київська";

  deliveryTown = "м. Київ";

  deliveryStreet = null;

  deliveryBuilding = null;

  deliveryCorp = null;

  deliveryFlat = null;

  deliveryZip = null;

  deliveryWarehouse = {
    Description: null,
    Number: null,
    ID: null,
    label: null,
    Ref: null,
  };

  extendedDelivery = {
    type: { country: "UA", carrier: "NovaPoshta" },
    deliveryTown: {
      Description: null,
      Region: null,
      Area: null,
      City: null,
      ID: null,
      label: null,
      Ref: null,
    },
    deliveryWarehouse: {
      Description: null,
      Number: null,
      ID: null,
      label: null,
      Ref: null,
    },
    deliveryStreet: null,
  };

  prim = "";

  withError = {
    phone: null,
    name: null,
    secondname: null,
    soname: null,
    email: null,
    paymentType: null,
    deliveryType: null,
    deliveryRegion: null,
    deliveryArea: null,
    deliveryTown: null,
    deliveryStreet: null,
    deliveryBuilding: null,
    deliveryCorp: null,
    deliveryFlat: null,
    deliveryZip: null,
    deliveryWarehouse: {
      Description: null,
      Number: null,
      ID: null,
      label: null,
      Ref: null,
    },
    prim: "",
    withError: false,
  };

  profile = {
    isLogged: false,
    blocktime: 0,
    loginstry: 0,
    profileLoaded: false,
    loadError: false,
    error: null,
    registerError: null,
    errorCode: null,
    restoreMessage: null,
    lang: "UA",
    ID: null,
    balance: 0,
    currency: null,
    email: null,
    phone: null,
    regDate: null,
    status: 0,
    userGroup: 0,
    userName: null,
    userSoname: null,
    allowWatchArticles: false,
    shippingType: 0,
    paymentType: 0,
    apiKey: null,
    likes: {},
    likesItems: {},
    likesImages: {},
    clients: {},
  };

  shippingType = 2;

  cost = 37500;

  items = { 528638: 1 };

  minOrder = {};

  itemComments = {};

  manager = "0";

  constructor() {
    this.phone = currentOrderStorage.phone;
    this.name = currentOrderStorage.name;
    this.secondname = currentOrderStorage.secondname;
    this.soname = currentOrderStorage.soname;
    this.email = currentOrderStorage.email;
    this.prim = currentOrderStorage.comment;
    this.paymentType = currentOrderStorage.paymentType;
    this.deliveryType = currentOrderStorage.deliveryType;
    this.shippingType = currentOrderStorage.deliveryType;
    this.cost = shoppingCartStorage.cost;
    this.items = shoppingCartStorage.items;
    this.deliveryRegion = currentOrderStorage.deliveryTown.Region;
    this.deliveryArea = currentOrderStorage.deliveryTown.Area;
    this.deliveryTown = currentOrderStorage.deliveryTown.name;
    this.deliveryStreet = {
      Description: currentOrderStorage.deliveryStreet.name,
      ID: currentOrderStorage.deliveryStreet.id,
      Number: null,
      Ref: currentOrderStorage.deliveryStreet.id,
      label: currentOrderStorage.deliveryStreet.name,
    };
    this.deliveryBuilding = currentOrderStorage.deliveryBuilding;
    this.deliveryCorp = currentOrderStorage.deliveryCorp;
    this.deliveryFlat = currentOrderStorage.deliveryFlat;
    this.deliveryZip = null;
    this.deliveryWarehouse = {
      Description: currentOrderStorage.deliveryWarehouse.name,
      Number: currentOrderStorage.deliveryWarehouse.number,
      ID: currentOrderStorage.deliveryWarehouse.id,
      label: currentOrderStorage.deliveryWarehouse.name,
      Ref: currentOrderStorage.deliveryWarehouse.id,
    };
    this.extendedDelivery = {
      type: { country: "UA", carrier: "NovaPoshta" },
      deliveryTown: {
        Description: currentOrderStorage.deliveryTown.name,
        Region: currentOrderStorage.deliveryTown.Region,
        Area: currentOrderStorage.deliveryTown.Area,
        City: currentOrderStorage.deliveryTown.City,
        ID: currentOrderStorage.deliveryTown.id,
        label: currentOrderStorage.deliveryTown.name,
        Ref: currentOrderStorage.deliveryTown.id,
      },
      deliveryWarehouse: {
        Description: currentOrderStorage.deliveryWarehouse.name,
        Number: currentOrderStorage.deliveryWarehouse.number,
        ID: currentOrderStorage.deliveryWarehouse.id,
        label: currentOrderStorage.deliveryWarehouse.name,
        Ref: currentOrderStorage.deliveryWarehouse.id,
      },
      deliveryStreet: {
        Description: currentOrderStorage.deliveryStreet.name,
        ID: currentOrderStorage.deliveryStreet.id,
        Number: null,
        Ref: currentOrderStorage.deliveryStreet.id,
        label: currentOrderStorage.deliveryStreet.name,
      },
    };
    this.profile = {
      isLogged: currentUserStorage.isLogged,
      blocktime: 0,
      loginstry: 0,
      profileLoaded: false,
      loadError: false,
      error: null,
      registerError: null,
      errorCode: null,
      restoreMessage: null,
      lang: "UA",
      ID: currentUserStorage.ID,
      balance: currentUserStorage.balance,
      currency: currentUserStorage.currency,
      email: currentUserStorage.email,
      phone: currentUserStorage.phone,
      status: currentUserStorage.status,
      userGroup: currentUserStorage.userGroup,
      userName: currentUserStorage.userName,
      userSoname: currentUserStorage.userSoname,
      allowWatchArticles: currentUserStorage.allowWatchArticles,
      // shippingType: currentUserStorage.shippingType,
      // paymentType: currentUserStorage.paymentType,
      // apiKey: currentUserStorage.apiKey,
    };
  }
}

export default RemoteOrderModel;
