import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "./api/urls";
import currentOrderStorage from "./orderStorage";
import shoppingCartStorage from "./shoppingCartStorage";
import orderStorage from "./orderStorage";

class CurrentUserStorage {
  ID = null;

  allowWatchArticles = false;

  apiKey = null;

  balance = 0;

  currency = "UAH";

  email = null;

  isLogged = false;

  manager = 0;

  phone = null;

  smstry = null;

  status = 0;

  userGroup = 0;

  userName = null;

  userSecondName = null;

  userSoname = null;

  isLoaded = false;

  settings = {};

  language = "ru";

  enabled = false;

  authData = {
    login: "",
    password: "",
  };

  authStatusMessage = "";

  regData = {
    login: "",
    password: "",
    userName: "",
    userSurname: "",
  };

  regStatusMessage = "";

  changedContent = {};

  changedPassword = {};

  get isChanged() {
    return Object.keys(this.changedContent).length > 0;
  }

  get isAdmin() {
    return this.userGroup === 1 || this.userGroup === 2 || this.userGroup === 3;
  }

  get isStaff() {
    // return false;
    return (
      this.userGroup === 1 ||
      this.userGroup === 2 ||
      this.userGroup === 3 ||
      this.userGroup === 4
    );
  }

  get isCustomer() {
    return this.userGroup === 0;
  }

  get isAuthDataFilled() {
    return (
      this.authData.login.match(/^\+\d{12,12}$/) &&
      this.authData.password.length > 0
    );
    // && this.authData.password.length > 8
  }

  get isRegisterLoginValid() {
    if (this.regData.login.length !== 13) return false;
    if (!this.regData.login.match(/^\+\d{12,12}$/)) return false;
    return true;
  }

  get isAuthLoginValid() {
    if (this.authData.login.length !== 13) return false;
    if (!this.authData.login.match(/^\+\d{12,12}$/)) return false;
    return true;
  }

  get isAuthPasswordValid() {
    if (this.authData.password.length < 1) return false;
    return true;
  }

  get isRegisterPasswordValid() {
    if (this.regData.password.length < 1) return false;
    return true;
  }

  get isRegisterUserNameValid() {
    if (this.regData.userName.length < 2) return false;
    return true;
  }

  get isRegisterUserSurNameValid() {
    if (this.regData.userSurname.length < 2) return false;
    return true;
  }

  get isRegisterDataFilled() {
    return (
      this.regData.login.length == 13 &&
      this.regData.login.match(/^\+\d{12,12}$/) &&
      this.regData.password.length > 0 &&
      this.regData.userName.length > 0 &&
      this.regData.userSurname.length > 0 &&
      this.enabled === true
    );
  }

  get isAuthDataStartFill() {
    return this.authData.login.length > 0 || this.authData.password.length > 0;
  }

  get isRegDataStartFill() {
    return (
      this.regData.login.length > 0 ||
      this.regData.password.length > 0 ||
      this.regData.userName.length > 0 ||
      this.regData.userSurname.length > 0
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  setField = (fieldName, value) => {
    if (this[fieldName] !== undefined) {
      this[fieldName] = value;
      this.changedContent[fieldName] = value;
    }
  };

  setPasswordField = (fieldName, newValue) => {
    this.changedPassword[fieldName] = newValue;
  };

  clearChangedContent = () => (this.changedContent = {});

  clearChangedPassword = () => (this.changedPassword = {});

  toggleCheckbox = () => (this.enabled = !this.enabled);

  setUser = (user) => {
    if (user) {
      this.ID = user.ID;
      this.allowWatchArticles = user.allowWatchArticles === "1";
      this.apiKey = user.apiKey;
      this.balance = +user.balance;
      this.currency = user.currency || this.currency;
      this.email = user.email;
      this.isLogged = user.isLogged;
      this.manager = +user.manager;
      this.phone = user.phone;
      this.smstry = +user.smstry;
      this.status = +user.status;
      this.userGroup = +user.userGroup;
      this.userName = user.userName;
      this.userSoname = user.userSoname;
      this.userSecondName = user.userSecondName;
      if (shoppingCartStorage.count > 0) {
        if (user.ID) shoppingCartStorage.saveCart();
      } else if (user.ID) shoppingCartStorage.loadCart();
    }
  };

  loadProfile = (inChain, cb) => {
    axios.get(`${urls.baseUrl}/api/v3/profile`).then((response) => {
      runInAction(() => {
        const profile = response?.data?.profile;
        const structures = response?.data?.structure;
        if (profile) {
          this.setUser(profile);
        }
        if (structures) {
          // @todo: Доделать загрузку категорий
        }
        this.isLoaded = true;
        if (cb) cb(null, true);
      });
    });
  };

  login = () => {
    this.authStatusMessage = "";
    axios
      .post(`${urls.baseUrl}/api/v7/auth/login`, {
        login: this.authData.login,
        password: this.authData.password,
      })
      .then((response) => {
        if (response?.data?.status == 200) {
          this.setUser(response?.data?.profile);
        } else {
          this.authStatusMessage = response?.data?.message || "Error";
        }
      });
  };

  logout = () => {
    axios.post(`${urls.baseUrl}/api/profile/logout`, {}).then((res) => {
      this.ID = null;
      this.allowWatchArticles = false;
      this.apiKey = null;
      this.balance = 0;
      this.currency = "UAH";
      this.email = null;
      this.isLogged = false;
      this.manager = 0;
      this.phone = null;
      this.smstry = null;
      this.status = 0;
      this.userGroup = 0;
      this.userName = null;
      this.userSoname = null;
      this.userSecondName = null;
      this.isLoaded = true;
      this.settings = {};
      shoppingCartStorage.clearShoppingCart();
      orderStorage.clearOrder();
    });
  };

  register = () => {
    axios
      .post(`${urls.baseUrl}/api/v7/auth/register`, {
        login: this.regData.login,
        password: this.regData.password,
        userName: this.regData.userName,
        userSurname: this.regData.userSurname,
        withoutSms: true,
      })
      .then((response) => {
        if (response?.data?.status == 200) {
          this.setUser(response?.data?.profile);
        } else {
          this.regStatusMessage = response?.data?.message || "Error";
        }
      });
  };

  setAuthLogin = (login) => {
    this.authData.login = login;
    currentOrderStorage.changeField("loginPhone", login);
  };

  setAuthPassword = (password) => {
    this.authData.password = password;
  };

  setRegUserSurname = (text) => (this.regData.userSurname = text);

  setRegUserName = (text) => (this.regData.userName = text);

  setRegPassword = (text) => (this.regData.password = text);

  setRegLogin = (text) => {
    this.regData.login = text;
    currentOrderStorage.changeField("registerPhone", text);
  };

  saveProfile = () => {
    axios
      .post(`${urls.baseUrl}/api/v7/profile/save`, {
        profile: this.changedContent,
      })
      .then((resp) => {
        runInAction(() => {
          this.clearChangedContent();
        });
        return resp;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  changePassword = () => {
    axios
      .post(`${urls.baseUrl}/api/v7/profile/changePass`, {
        profile: {
          oldPassword: this.changedPassword.oldPassword,
          newPassword: this.changedPassword.newPassword,
          newPasswordConfirm: this.changedPassword.newPasswordConfirm,
        },
      })
      .then((resp) => {
        runInAction(() => {
          if (resp.data?.status === 200) {
            this.changedPassword = {};
            toast.success("Password changed");
          } else if (resp.data?.message) {
            toast.error(resp.data?.message);
          }
        });
        return resp;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // https://kid.ua.moda/api/v7/profile/save
  // {"profile":{"userName":"Serhii2"}}

  // Отправка письма о замене пароля
  // https://kid.ua.moda/api/profile/forgot
}

const currentUserStorage = new CurrentUserStorage();

export default currentUserStorage;
