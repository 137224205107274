class NovaPoshtaWarehouseModel {
  Description = ""; // : "Відділення №1: вул. Пирогівський шлях, 135"

  ID = null; // "1ec09d88-e1c2-11e3-8c4a-0050568002cf"

  Number = null;

  constructor(data) {
    if (data) {
      this.Description = data.Description;
      this.ID = data.ID;
      this.Number = data.Number;
    }
  }
}

export default NovaPoshtaWarehouseModel;
