class DeliveryMethodModel {
  ID = null;

  additional = [];

  allow = false;

  integration = null;

  name = null;

  type = 2;

  constructor(method) {
    if (method) {
      this.ID = +method.ID;
      this.integration = method.integration;
      this.allow = method.allow === "1";
      this.name = method.name;
      this.type = +method.type;
      if (method.additional) {
        this.additional = method.additional.map((v) => +v);
      }
    }
  }
}

export default DeliveryMethodModel;
