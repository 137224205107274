import { makeAutoObservable } from "mobx";
import MenuModel from "./MenuModel";
import menuStorage from "../menuStorage";

class FooterSectionModel {
  title = null;

  type = null;

  html = null;

  subtitle = null;

  items = [];

  constructor(data) {
    if (data) {
      this.title = data.title;
      this.type = data.type;
      switch (this.type) {
        case "socials":
          this.items = data.items.map((item) => {
            const newItem = { ...item };
            const net = newItem.network;
            const link = menuStorage.socials[net];
            // console.log("RQ:", net, newItem, link, menuStorage.socials);
            if (link) {
              newItem.enabled = true;
              newItem.link = link;
            } else {
              newItem.enabled = false;
            }
            if (newItem?.link?.includes("fufayka")) {
            }
            return new MenuModel(newItem);
          });
          break;
        default:
          this.items = data.items.map((item) => new MenuModel(item));
      }
      this.html = data.html || null;
      this.subtitle = data.subtitle || null;
    }
    makeAutoObservable(this);
  }
}

export default FooterSectionModel;
