class AttributesModel {
  barcode = null;

  yearOfProduction = null;

  group = null;

  collection = null;

  construction = null;

  material = null;

  feature = null;

  gender = null;

  mainSize = null;

  season = null;

  composition = null;

  countryOfProduction = null;

  insulation = null;

  ageGroup = null;

  // CvetHar: "mix"
  // EtoNabor: "1"
  // PlanovayaDataPostavki: "0000-00-00 00:00:00"
  // PlanovayaDataZakaza: "0000-00-00 00:00:00"
  // PodZakaz: "0"
  // Pol: "для хлопчика"
  // RazmerGruppa: null
  // RazmerHar: "104"
  // RazmerPostavshikaHar: ""
  // TGroup: null
  // VozrastSpisok: "3,4"
  // categoryId: "00000004098"
  // description: null
  // imagesCount: "1"
  // inLinks: "0"
  // reserved: "0"
  // reservettl: "0"
  // sizes: "104"
  // updated: "1619504895"
  // _fullcount: "15"
  // _ostcount: "0"
  // _parent: null
}

export default AttributesModel;
