import layoutStorage from "./layoutStorage";

function init() {
  return layoutStorage.init();
}

export default {
  init,
  layoutStorage,
};
