import i18next from "i18next";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import get from "lodash/get";
import MegaMenuItemModel from "./models/MegaMenuItemModel";
import MenuModel from "./models/MenuModel";
import filtersStorage from "./filtersStorage";
import urls from "./api/urls";
import getCookies from "../helpers/getCookies";
import Core from "../core/Core";
import menuStorage from "./menuStorage";
import logger from "../helpers/logger";

class LayoutStorage {
  defaultLanguage = "uk";

  allowFaq = true;

  allowProfile = true;

  allowLikes = false;

  allowCompare = false;

  allowCart = true;

  allowCurrencies = false;

  allowMultilang = true;

  allowSubscribe = false;

  allowQuickView = false;

  allowProductCardDetails = false;

  allowProductCardDescription = false;

  allowInfoBlockInPopUp = true;

  allowSearch = true;

  allowViewOfItem = true;

  allowUserAccount = true;

  showTape = false;

  isMenuOpened = false;

  isSettingsOpened = false;

  isDataLoaded = true;

  isEditorEnabled = false;

  /**
   * Разрешает или запрещает компонент прелоадера в карточке товара
   *
   * @type {boolean}
   */
  allowImagesSpinner = true;

  shippingCosts = [
    {
      min: 0,
      max: 50000,
      cost: 2900,
      className: "popup-cart__free-shipping_red",
    },
    {
      min: 50001,
      max: 100000,
      cost: 4900,
      className: "popup-cart__free-shipping_green",
    },
    {
      min: 100001,
      max: 10000000000,
      cost: 0,
      className: "popup-cart__free-shipping_green",
    },
  ];

  home = {
    allowHeroBlock: true,
    allowHomeCategories: true,
    allowProductCardList: true,
    allowTrendingItems: false,
    allowBrandsList: true,
    allowBlogSlider: false,
    allowSocialSlider: false,
    texts: {
      beforeHero: "",
      beforeHomeCategories: "",
      beforeProducts: "",
      afterAll: "",
      beforeBrands: "",
      beforePromo: "",
    },
  };

  wishlist = {
    allowModule: false,
  };

  comparation = {
    allowModule: false,
  };

  products = {
    allowTrendingItems: true,
    allowBigPromoBlock: false,
  };

  popups = {
    service: false,
    account: false,
    cart: false,
    sidebar: false,
    blog: false,
    wishlist: false,
    confirmtransfer: false,
    deliveryreturn: false,
    sizeguide: false,
    quickview: false,
    subscribe: false,
    compare: false,
    wishlistFull: false,
    navigation: false,
    filters: false,
    search: false,
  };

  drawerOpened = false;

  popupsData = {
    quickview: {
      refNum: 941447,
    },
    headerTape: new MenuModel({
      title: "Финальная распродажа: 20% скидка",
      slug: "new",
      segment: "catalog",
    }),
  };

  catalogLayout = {
    columns: 4,
    allowShowSorts: true,
  };

  categories = [];

  rawCategories = [];

  brands = {};

  currentLanguage = "ru";

  currentCurrency = "UAH";

  shopName = "Closes for children";

  logo = `${urls.baseUrl}/images/logo.png`;

  currentCategoryID = 48;

  currentCategory = {
    title: "",
    link: "",
    gender: "",
    // filters: [],
    texts: {},
  };

  editablePath = null;

  logoImageUrl = "/images/logo192.png";

  allowCookieNotifications = true;

  company = {
    phone: "+380 50 338 5663",
  };

  error404 = false;

  get parentCategory() {
    let realParentCat;
    for (const parentCat of this.categories) {
      const foundIndex = parentCat.children.findIndex(
        (v) => v.id === this.currentCategory.id
      );
      if (foundIndex >= 0) {
        realParentCat = parentCat;
      }
    }
    return realParentCat;
  }

  get haveNotifications() {
    return this.allowCookieNotifications;
  }

  get brandsList() {
    return Object.keys(this.brands).map((b) => {
      const brand = this.brands[b];
      brand.link = Core.routing.createRoute(
        Core.routing.routingSegments.SEGMENT_BRAND,
        {
          brand: brand.name,
          compose: true,
        }
      );
      brand.altText = brand.name;
      return brand;
    });
  }

  get currentCategoryName() {
    const field = "title"; // `name_${layoutStorage.currentLanguage}`;
    let name = "";
    if (this.currentCategory) {
      name = this.currentCategory[field] || "";
      if (
        Object.values(filtersStorage.currentFilters.group).filter((v) => v)
          .length > 0
      ) {
        name += ` (${Object.keys(filtersStorage.currentFilters.group)
          .filter((v) => v)
          .map((v) => i18next.t(v))
          .join(", ")}) `;
      }
      // if (
      //   Object.values(filtersStorage.currentFilters.brands).filter((v) => v)
      //     .length > 0
      // ) {
      //   name += ` ${Object.keys(filtersStorage.currentFilters.brands)
      //     .filter((v) => v)
      //     .join(", ")}`;
      // }
      if (
        Object.values(filtersStorage.currentFilters.sizes).filter((v) => v)
          .length > 0
      ) {
        name += ` р/р ${Object.keys(filtersStorage.currentFilters.sizes)
          .filter((v) => v)
          .join(", ")}`;
      }
      if (
        Object.values(filtersStorage.currentFilters.seasons).filter((v) => v)
          .length > 0
      ) {
        name += ` сезон ${Object.keys(filtersStorage.currentFilters.seasons)
          .filter((v) => v)
          .join(", ")}`;
      }
      if (
        Object.values(filtersStorage.currentFilters.vgroup).filter((v) => v)
          .length > 0
      ) {
        name += ` возраст ${Object.keys(filtersStorage.currentFilters.vgroup)
          .filter((v) => v)
          .join(", ")}`;
      }
    }
    return name;
  }

  setCategories = (categories) => {
    this.categories = [];
    this.rawCategories = {};
    categories
      .filter((c) => c.parent == 0)
      .forEach((cat) => {
        this.rawCategories[cat.id] = cat;
        this.categories.push({
          ...cat,
          type: cat.type || "group",
          enabled: cat.enabled != 0,
          children:
            cat.enabled == 1 ? [new MegaMenuItemModel(cat, "header")] : [],
        });
      });
    categories
      .filter((c) => c.parent != 0)
      .forEach((cat) => {
        this.rawCategories[cat.id] = cat;
        const parent = this.categories.find((c) => c.id === cat.parent);
        if (parent) {
          parent.children.push(new MegaMenuItemModel(cat));
        }
      });
    menuStorage.setCategories(this.categories);
  };

  setBrands = (brands) => {
    this.brands = brands || {};
  };

  openPopup = (popup) => {
    this.drawerOpened = true;
    this.popups[popup] = true;
  };

  closePopup = (popup) => {
    this.drawerOpened = false;
    this.popups[popup] = false;
  };

  setMenuOpenStatus = (status) => {
    this.isMenuOpened = status;
  };

  toggleSettingsOpened = () => {
    this.isSettingsOpened = !this.isSettingsOpened;
  };

  changeColumns = (layout, columns) => {
    if (this[layout] && this[layout].columns !== undefined) {
      this[layout].columns = columns;
    }
  };

  constructor() {
    this.allowCookieNotifications =
      localStorage.getItem("notification") !== "1";
    console.log(
      ' localStorage.getItem("notification")',
      localStorage.getItem("notification")
    );
    makeAutoObservable(this);
  }

  closeHeaderTape = () => {
    // @todo: Реализовать метод закрытия верхнего тейпа
    this.showTape = false;
  };

  burgerClick = () => {
    // @todo: Реализовать открытие основного меню по клику на бургере
    this.openPopup("navigation");
  };

  changeLanguage = (langCode) => {
    this.currentLanguage = langCode;
    i18next.changeLanguage(langCode || layoutStorage.defaultLanguage);
  };

  setCurrentCategory = (category) => {
    // console.log("HISTORY SET CATEGORY", category);
    this.currentCategory = category;
    if (category?.filters?.gender) {
      this.currentCategory.gender = Object.keys(category?.filters?.gender)[0];
    }
    this.currentCategoryID = category.id;
  };

  setCurrentLanguage = (language) => {
    if (language) {
      this.currentLanguage = language;
    }
  };

  toggleEditorEnable = () => {
    this.isEditorEnabled = !this.isEditorEnabled;
  };

  startEdit = (path) => {
    this.editablePath = this.editablePath === path ? null : path;
  };

  endEdit = (text) => {
    this.editablePath = null;
    if (text) {
      toast.info(text);
    }
  };

  hideCookieNotifications = () => {
    this.allowCookieNotifications = false;
  };

  set404 = (status = true) => {
    this.error404 = status;
  };

  init = () => {};

  goToMenu = (menuIndex) => {
    this.currentMenuPath.push(menuIndex);
  };

  handleChangeHomeText = (field, text) => (this.home.texts[field] = text);
}

const layoutStorage = new LayoutStorage();

export default layoutStorage;
