import { makeAutoObservable } from "mobx";

class AttributesStorage {
  collection = {};

  country = {};

  gender = {};

  group = {};

  konstrukciya = {};

  material = {};

  season = {};

  uteplitel = {};

  sostav = {};

  singles = {};

  get seasons() {
    return this.season;
  }

  fillData = (data) => {
    data.forEach((item) => {
      if (this[item.name] !== undefined) {
        this[item.name][item.value_uk] = item;
      }
    });
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const attributesStorage = new AttributesStorage();

export default attributesStorage;
