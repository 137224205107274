/* eslint-disable no-param-reassign */
import AttributesModel from "../../models/attributesModel";

function productAdapter(product, model) {
  model.refNum = product.KodVnutrennii || null;
  model.vendor = product.Brend || null;
  model.price = +product.Cena || 0;
  model.oldPrice = +product.CenaStaraya || 0;
  if (model.sizes_inStock !== undefined && model.sizes_inStock.length > 0) {
    model.sizes = []; // product.sizes_array || [];
    model.sizes.forEach((size) => {
      model.sizes_inStock[size] = 0;
    });
  }
  model.inStock = product.updated > 0 ? +product.KolichestvoOstatok || 0 : 0;
  model.inPack = +product.KolichestvoVNabore || 1;
  model.minimalOrder = +product.MinZakaz || 1;
  model.rrp = +product.PriceRetailRecommend || 0;
  model.description = product.description || null;
  model.mainColor = product.CvetHar || null;
  model.categories = product.categories || [];
  model.slug_ru = product.slug_ru || null;
  model.slug_uk = product.slug_uk || null;

  const title = product.Nomenklatura ? product.Nomenklatura.split("@") : [""];
  model.title = title[0].trim();

  const sku = product.Artikul ? product.Artikul.split("@") : [null];
  model.sku = sku[0] || null;

  const colors = [];
  model.colors = colors;

  const attributes = new AttributesModel();
  attributes.barcode = product.Barcode || null;
  attributes.yearOfProduction = product.GodIzgotovleniya || null;
  attributes.group = product.GruppaTovara || null;
  attributes.collection = product.Kollekciya || null;
  attributes.construction = product.Konstrukciya || null;
  attributes.material = product.Material || null;
  attributes.feature = product.Osobennost || null;
  attributes.feature_ru = product.Osobennost_ru || null;
  attributes.gender = product.Pol || null;
  attributes.mainSize = product.RazmerHar || null;
  attributes.season = product.SezonPrimeneniya || null;
  attributes.composition = product.SostavTkani
    ? product.SostavTkani.split(",")
    : null;
  attributes.countryOfProduction = product.StranaIzgotovitel || null;
  attributes.insulation = product.Uteplitel || null;
  attributes.ageGroup = product.VozrastnayaGruppa || null;
  model.attributes = attributes;

  // eslint-disable-next-line eqeqeq
  if (product.Novinka == "1") model.badges.newItem = true;
  // eslint-disable-next-line eqeqeq
  if (model.price != model.oldPrice) model.badges.onSale = true;

  // eslint-disable-next-line prefer-destructuring
  // model.selectedSize =
  //   model.sizes && model.sizes.length > 0 ? model.sizes[0] : null;
  model.selectedColor = model.mainColor;
  // eslint-disable-next-line no-underscore-dangle
  model.parent = product._parent;
  // model.AllowToSale = +product.AllowToSale;
  // Barcode: ""
  model.Brand_Artikul = product.Brand_Artikul;
  model.Brand_Artikul_Color = product.Brand_Artikul_Color;
  if (product.EtoNabor == "1") model.mainProduct = true;
  // MinZakaz: "5"
  // MinZakazEdinitsTovarov: "5"
  // PlanovayaDataPostavki: "0000-00-00 00:00:00"
  // PlanovayaDataZakaza: "0000-00-00 00:00:00"
  // PodZakaz: "0"
  // PriceRetailRecommend: "121500"
  // RazmerGruppa: null
  // RazmerPostavshikaHar: "120"
  // VozrastSpisok: "5,6"
  // categoryId: "00000004089"
  model.category_id = product.category_id;
  model.collection_id = product.collection_id;
  model.colorBase_id = product.colorBase_id;
  model.color_id = product.color_id;
  model.country_id = product.country_id;
  model.description = product.description;
  model.description_ru = product.description_ru;
  model.gender_id = product.gender_id;
  model.group_id = product.group_id;
  model.konstrukciya_id = product.konstrukciya_id;
  model.material_id = product.material_id;
  model.metaDescription = product.metaDescription;
  model.metaDescription_ru = product.metaDescription_ru;
  model.metaTitle = product.metaTitle;
  model.metaTitle_ru = product.metaTitle_ru;
  model.name = product.name;
  model.name_ru = product.name_ru;
  model.season_id = product.season_id;
  model.sostavTkani_ru = product.sostavTkani_ru;
  model.uteplitel_id = product.uteplitel_id;
}

export default productAdapter;
