import { makeAutoObservable } from "mobx";
import ContentPageModel from "./models/ContentPageModel";

class ContentStorage {
  pages = {};

  constructor() {
    makeAutoObservable(this);
  }

  setContent = (structures) => {
    if (structures) {
      structures.forEach((structure) => {
        this.pages[structure.slug] = new ContentPageModel(structure);
      });
    }
    // console.log("CONTENT", this.pages);
  };
}

const contentStorage = new ContentStorage();

export default contentStorage;
