class ImageModel {
  width = 0;

  height = 0;

  originalImageURL = null;

  bigImageURL = null;

  smallImageUrl = null;

  middleImageUrl = null;

  altText = "";

  mainImage = false;

  srcSet = [];
}

export default ImageModel;
