import i18n from "i18next";
import attributesStorage from "../attributesStorage";
import layoutStorage from "../layoutStorage";
import Core from "../../core/Core";

class FilterItemModel {
  count = 0;

  segment = "";

  realName = "";

  slug = null;

  get link() {
    let attr = attributesStorage[this.segment]?.[this.realName]
      ? attributesStorage[this.segment][this.realName][
          `slug_${layoutStorage.currentLanguage}`
        ]
      : null;
    if (!attr) attr = i18n.t(this.realName);
    if (this.segment === "cities") {
      attr = this.realName.replace(/\s/g, "_").toLowerCase();
    }
    return Core.routing.createRoute(this.segment, {
      slug: attr,
      compose: true,
    });
  }

  get title() {
    let title = attributesStorage[this.segment]
      ? attributesStorage[this.segment][
          `value_${layoutStorage.currentLanguage}`
        ]
      : null;
    if (!title) {
      title = i18n.t(this.realName);
    }
    return title;
  }

  get name() {
    let attr = attributesStorage[this.segment]?.[this.realName]
      ? attributesStorage[this.segment][this.realName][
          `slug_${layoutStorage.currentLanguage}`
        ]
      : null;
    // console.log(
    //   "FILTER ATTR",
    //   attr,
    //   this.segment,
    //   layoutStorage.currentLanguage,
    //   attributesStorage
    // );
    if (!attr) {
      attr = i18n.t(this.realName);
      return Core.routing.createRoute(
        Core.routing.routingSegments.SEGMENT_TRANSLIT,
        {
          slug: attr,
        }
      );
    }
    return attr;
  }

  constructor(data) {
    if (data) {
      this.count = data.count;
      this.realName = data.name;
      this.segment = data.segment;
      if (data.segment === "cities") {
        this.realName = data.name.replace(/\s/g, "_").toLowerCase();
      }
    }
  }
}

export default FilterItemModel;
