import { makeAutoObservable } from "mobx";

class SitemapStorage {
  structure = [
    {
      title: "Для девочки",
      link: "/ru/catalog/cat-dlya_devochki",
      children: [
        {
          title: "Блузки, рубашки",
          link: "/ru/catalog/cat-bluzki__rubashki_dlya_devochki",
        },
        {
          title: "Брюки, штаны",
          link: "/ru/catalog/cat-bryuki__shtani_dlya_devochki",
        },
        {
          title: "Верхняя одежда",
          link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_devochki",
        },
        {
          title: "Комбинезоны, полукомбинезоны",
          link: "/ru/catalog/cat-kombinezoni__polukombinezoni_dlya_devochki",
        },
        {
          title: "Костюмы",
          link: "/ru/catalog/cat-kostyumi_dlya_devochki",
        },
        {
          title: "Кофты, свитера, регланы",
          link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_devochki",
        },
        {
          title: "Нижнее белье",
          link: "/ru/catalog/cat-nizhnee_bele_dlya_devochki",
        },
        {
          title: "Термобелье",
          link: "/ru/catalog/cat-termobele_dlya_devochki",
        },
        {
          title: "Платья, сарафаны",
          link: "/ru/catalog/cat-platya__sarafani_dlya_devochki",
        },
        {
          title: "Пляжная одежда",
          link: "/ru/catalog/cat-plyazhnaya_odezhda_dlya_devochki",
        },
        {
          title: "Футболки, топы",
          link: "/ru/catalog/cat-futbolki__topi_dlya_devochki",
        },
        {
          title: "Халаты, пижамы",
          link: "/ru/catalog/cat-khalati__pizhami_dlya_devochki",
        },
        {
          title: "Шорты, капри",
          link: "/ru/catalog/cat-shorti__kapri_dlya_devochki",
        },
        {
          title: "Юбки",
          link: "/ru/catalog/cat-yubki_dlya_devochki",
        },
        {
          title: "Пиджаки, жакеты, жилетки",
          link: "/ru/catalog/cat-pidzhaki__zhaketi__zhiletki_dlya_devochki",
        },
        {
          title: "Джинсы",
          link: "/ru/catalog/cat-dzhinsi_dlya_devochki",
        },
      ],
    },
    {
      title: "Для мальчика",
      link: "/ru/catalog/cat-dlya_malchika",
      children: [
        {
          title: "Брюки, штаны",
          link: "/ru/catalog/cat-bryuki__shtani_dlya_malchika",
        },
        {
          title: "Верхняя одежда",
          link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_malchika",
        },
        {
          title: "Комбинезоны, полукомбинезоны",
          link: "/ru/catalog/cat-kombinezoni__polukombinezoni_dlya_malchika",
        },
        {
          title: "Костюмы",
          link: "/ru/catalog/cat-kostyumi_dlya_malchika",
        },
        {
          title: "Кофты, свитера, регланы",
          link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_malchika",
        },
        {
          title: "Нижнее белье",
          link: "/ru/catalog/cat-nizhnee_bele_dlya_malchika",
        },
        {
          title: "Пиджаки, жакеты, жилетки",
          link: "/ru/catalog/cat-pidzhaki__zhaketi__zhiletki_dlya_malchika",
        },
        {
          title: "Пляжная одежда",
          link: "/ru/catalog/cat-plyazhnaya_odezhda_dlya_malchika",
        },
        {
          title: "Рубашки",
          link: "/ru/catalog/cat-rubashki_dlya_malchika",
        },
        {
          title: "Термобелье",
          link: "/ru/catalog/cat-termobele_dlya_malchika",
        },
        {
          title: "Футболки, тенниски",
          link: "/ru/catalog/cat-futbolki__tenniski_dlya_malchika",
        },
        {
          title: "Халаты, пижамы",
          link: "/ru/catalog/cat-khalati__pizhami_dlya_malchika",
        },
        {
          title: "Шорты, бриджи",
          link: "/ru/catalog/cat-shorti__bridzhi_dlya_malchika",
        },
        {
          title: "Джинсы",
          link: "/ru/catalog/cat-dzhinsi_dlya_malchika",
        },
      ],
    },
    {
      title: "Для малышей",
      link: "/ru/catalog/cat-dlya_malishei",
      children: [
        {
          title: "Боди, человечки, песочники",
          link: "/ru/catalog/cat-bodi__chelovechki__pesochniki_dlya_malishei",
        },
        {
          title: "Комплекты подарочный",
          link: "/ru/catalog/cat-komplekti_podarochnii_dlya_malishei",
        },
        {
          title: "Ползунки, распашонки",
          link: "/ru/catalog/cat-polzunki__raspashonki_dlya_malishei",
        },
        {
          title: "Комплекты",
          link: "/ru/catalog/cat-komplekti_dlya_malishei",
        },
        {
          title: "Комбинезоны, полукомбинезоны",
          link: "/ru/catalog/cat-kombinezoni__polukombinezoni_dlya_malishei",
        },
        {
          title: "Конверты, одеяла",
          link: "/ru/catalog/cat-konverti__odeyala_dlya_malishei",
        },
        {
          title: "Пеленки, уголки для купания",
          link: "/ru/catalog/cat-pelenki__ugolki_dlya_kupaniya_dlya_malishei",
        },
        {
          title: "Пинетки, царапки, носовички",
          link: "/ru/catalog/cat-pinetki__tsarapki__nosovichki_dlya_malishei",
        },
      ],
    },
    {
      title: "Аксессуары",
      link: "/ru/catalog/cat-aksessuari",
    },
    {
      title: "Спортивная одежда",
      link: "/ru/catalog/cat-sportivnaya_odezhda",
    },
    { title: "Все товары", link: "/ru/catalog/cat-all" },
  ];

  constructor() {
    makeAutoObservable(this);
  }
}

const sitemapStorage = new SitemapStorage();

export default sitemapStorage;
