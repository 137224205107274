// import axios from "axios";
import { /* runInAction, */ makeAutoObservable } from "mobx";
import get from "lodash/get";
import set from "lodash/set";
import FooterSectionModel from "./models/FooterSectionModel";
import MenuModel from "./models/MenuModel";
import MegaMenuItemModel from "./models/MegaMenuItemModel";
import layoutStorage from "./layoutStorage";
// import FooterSectionModel from "./models/FooterSectionModel";
// import MenuModel from "./models/MenuModel";
// import logger from "../helpers/logger";

// import homePageStorage from "./forPages/homePageStorage";
// import layoutStorage from "./layoutStorage";
// import filtersStorage from "./filtersStorage";

class MenuStorage {
  menu = [];

  mobileMenu = [];

  footerSections = [];

  socials = {};

  copyright =
    '<p className="mb-0">\n' +
    "                  &copy; 2019 " +
    "                </p>";

  currentMenuPath = [];

  get currentMenu() {
    if (this.currentMenuPath.length === 0) {
      return this.menu.filter((v) => v.enabled === true);
    }
    // const path = this.currentMenuPath.join(".");
    // return get(this.categories, path);
    console.log(
      "MENU DATA PATH",
      this.menu,
      this.currentMenuPath,
      this.currentMenuPath.length,
      get(this.menu, this.currentMenuPath.join("."))
    );
    let currentMenu = [...get(this.menu, this.currentMenuPath.join("."))];
    if (this.currentMenuPath.length === 2) {
      const fourIndex = currentMenu.findIndex((v) => v.id == 4);
      currentMenu = currentMenu.concat(currentMenu[fourIndex].children);
      currentMenu.slice(fourIndex, 1);
    }
    return currentMenu;
  }

  goToMenu = (index) => {
    this.currentMenuPath.push(index);
    this.currentMenuPath.push("children");
    console.log("MENU DATA: ", "GO TO INDEX", index);
  };

  goMenuBack = () => {
    this.currentMenuPath.pop();
    this.currentMenuPath.pop();
  };

  setMainMenu = (data) => {
    console.log("START MAIN MENU");
    this.menu = data ? data.map((m) => new MenuModel(m)) : [];
    console.log("END MAIN MENU");
  };

  constructor() {
    makeAutoObservable(this);
  }

  setSocials = (data) => (this.socials = data || {});

  setFooterSections = (data) => {
    this.footerSections = data
      ? data.map((m) => new FooterSectionModel(m))
      : [];
  };

  setCategories = (categories, type = "catalog") => {
    setTimeout(() => {
      const currentMenu = this.menu.find((v) => v.type == type);
      if (currentMenu && layoutStorage.categories) {
        currentMenu.children = layoutStorage.categories;
      }
    }, 100);
  };
}

const menuStorage = new MenuStorage();

export default menuStorage;
