import { makeAutoObservable } from "mobx";

class CheckBoxFilterModel {
  type = "checkboxes";

  title = "";

  columns = 1;

  field = "sizes";

  dontShowAppend = true;

  items = [];

  opened = true;

  enabled = true;

  constructor(data) {
    if (data) {
      this.title = data.title;
      if (data.type) this.type = data.type;
      if (data.columns) this.columns = data.columns;
      if (data.field) this.field = data.field;
      if (data.dontShowAppend) this.dontShowAppend = data.dontShowAppend;
      if (data.opened !== undefined) this.opened = data.opened;
      if (data.items) this.items = data.items;
      if (data.enabled !== undefined) this.enabled = data.enabled;
    }
    makeAutoObservable(this);
  }

  toggleOpened = () => {
    this.opened = !this.opened;
  };
}

export default CheckBoxFilterModel;
