/* eslint-disable eqeqeq */
import { keys, makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import productsListStorage from "./productsListStorage";
import currentOrderStorage from "./orderStorage";
import urls from "./api/urls";

class ShoppingCartStorage {
  cartLoaded = false;

  cost = 0;

  count = 0;

  invalidate = true;

  itemComments = {};

  items = {};

  minOrder = {};

  orderError = false;

  reserved = {};

  virtualItems = {};

  get shippingCost() {
    if (!currentOrderStorage.currentDeliveryMethod.integration) return 0;
    if (
      currentOrderStorage.currentDeliveryMethod.integration ===
        "novaposhta:warehouse" &&
      this.cost < 50000
    )
      return 2900;
    if (
      currentOrderStorage.currentDeliveryMethod.integration ===
        "novaposhta:address" &&
      this.cost < 100000
    )
      return 4900;
    return 0;
  }

  get freeShippingDiffCalc() {
    if (!currentOrderStorage.currentDeliveryMethod.integration) return 0;
    if (
      currentOrderStorage.currentDeliveryMethod.integration ===
        "novaposhta:warehouse" &&
      this.cost < 50000
    )
      return 50000 - this.cost;
    if (
      currentOrderStorage.currentDeliveryMethod.integration ===
        "novaposhta:address" &&
      this.cost < 100000
    )
      return 100000 - this.cost;
    return 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setCart = (cart) => {
    if (cart) {
      this.cost = +cart.cost;
      this.count = +cart.count;
      this.itemComments = cart.itemComments;
      this.items = cart.items;
      this.minOrder = cart.minOrder;
      this.orderError = false;
      this.reserved = cart.reserved;
      this.virtualItems = cart.virtualItems;
      this.cartLoaded = true;
      this.invalidate = false;
      this.reaclculateCart();
      if (this.cost !== cart?.cost || this.count !== cart?.count) {
        this.saveCart();
      }
    }
  };

  placeToCart(refNum) {
    if (!this.items[refNum]) {
      this.items[refNum] = 1;
      this.count += 1;
      const product = productsListStorage.products[refNum];
      if (product) {
        this.cost += product.price;
      }
      this.cloneProductToCart(refNum);
      this.reaclculateCart();
      this.saveCart().then(() => this.loadCart());
    }
    console.log("SHOPPING CART PLACE", this, productsListStorage.cartItems);
  }

  cloneProductToCart = (refNum) => {
    const product = productsListStorage.products[refNum];
    console.log("SHOPPING CART CLONE", product);
    if (product) {
      const parentProduct = productsListStorage.products[product.parent];
      productsListStorage.cartItems[product.refNum] = product;
      if (parentProduct) {
        productsListStorage.cartItems[parentProduct.refNum] = parentProduct;
      }
    }
  };

  increaseItems(refNum) {
    const product = productsListStorage.products[refNum];
    if (product) {
      if (!this.items[refNum]) {
        this.items[refNum] = 0;
        this.cloneProductToCart(refNum);
      }
      if (product.inStock > this.items[refNum]) {
        this.items[refNum] += 1;
        this.count += 1;
        this.cost += product.price;
        // this.reaclculateCart();
        this.saveCart();
      }
    }
    console.log("SHOPPING CART increase", product, this);
  }

  decreaseItems = (refNum) => {
    if (this.items[refNum]) {
      this.items[refNum] -= 1;
      if (this.items[refNum] < 0) {
        this.items[refNum] = 0;
      } else {
        this.count -= 1;
        const product = productsListStorage.products[refNum];
        if (product) {
          this.cost -= product.price;
        }
      }
      if (this.items[refNum] === 0) delete this.items[refNum];
      // this.reaclculateCart();
      this.saveCart();
    }
  };

  loadCart = (arg1, cb) => {
    axios.post(`${urls.baseUrl}/api/cart/getData`).then((resp) => {
      runInAction(() => {
        productsListStorage.setCartItems(
          resp?.data?.cartItems,
          resp?.data?.images
        );
        this.setCart(
          resp?.data?.cart || {
            cost: 0,
            count: 0,
            itemComments: "",
            items: {},
            minOrder: {},
            reserved: {},
            virtualItems: {},
          }
        );
        if (cb) cb(null, "true");
      });
    });
  };

  saveCart = () =>
    axios.post(`${urls.baseUrl}/api/cart/add`, { cart: this }).then((resp) => {
      this.cartLoaded = true;
    });

  reaclculateCart = () => {
    let newcost = 0;
    let newcount = 0;
    let err = false;
    Object.keys(this.items).forEach((refNum) => {
      const product =
        productsListStorage.cartItems[refNum] ||
        productsListStorage.products[refNum] ||
        null;
      if (!product) {
        err = true;
        return;
      }
      const currentCount =
        product.inStock > this.items[refNum]
          ? this.items[refNum]
          : product.inStock;
      if (this.items[refNum] != currentCount) this.items[refNum] = currentCount;
      if (currentCount > 0) {
        newcount += currentCount;
        newcost += currentCount * product.price;
      } else {
        delete this.items[refNum];
      }
    });
    if (!err) {
      this.cost = newcost;
      this.count = newcount;
    }
  };

  removeFromCart = (refNum) => {
    if (this.items[refNum]) {
      delete this.items[refNum];
      this.reaclculateCart();
      this.saveCart();
    }
  };

  setQuantity = (refNum, value) => {
    const newValue = +value;
    const product = productsListStorage.cartItems[refNum];
    if (product) {
      if (newValue > 0) {
        this.items[refNum] = Math.min(product.inStock, newValue);
        // this.reaclculateCart();
      }
      this.saveCart();
    }
  };

  clearShoppingCart = () => {
    this.cartLoaded = false;
    this.cost = 0;
    this.count = 0;
    this.invalidate = true;
    this.itemComments = {};
    this.items = {};
    this.minOrder = {};
    this.orderError = false;
    this.reserved = {};
    this.virtualItems = {};
  };
}

const shoppingCartStorage = new ShoppingCartStorage();

export default shoppingCartStorage;
