import { makeAutoObservable } from "mobx";

class AccountPageStorage {
  title = "Profile";

  mainText =
    'Have a question? You may find an answer in our <a href="pages/answers-to-your-questions" class="btn-link">FAQs</a>.<br />But you can\n also contact us:';

  constructor() {
    makeAutoObservable(this);
  }
}

const accountPageStorage = new AccountPageStorage();

export default accountPageStorage;
