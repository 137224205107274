import { makeAutoObservable, runInAction } from "mobx";
import requestPost from "../api/requestPost";
import urls from "./api/urls";
import ProductModel from "./models/productModel";
import productsConverter from "./adapters/optModa/productsConverter";

class SearchStorage {
  searchString = "";

  formOpened = false;

  searchedProducts = {};

  searchedProductsList = [];

  activeSearch = false;

  constructor() {
    makeAutoObservable(this);
  }

  searchTyping = (e) => (this.searchString = e.target.value);

  openForm = () => {
    this.formOpened = true;
  };

  closeForm = () => {
    this.searchedProductsList = [];
    this.searchedProducts = {};
    this.formOpened = false;
    this.activeSearch = false;
  };

  startSearch = () => {
    requestPost(`${urls.baseUrl}/api/products/search`, {
      full: "",
      match: "0",
      searchValue: this.searchString,
    }).then((data) => {
      runInAction(() => {
        this.searchedProducts = {};
        this.searchedProductsList = data.filteredRows || [];
        const result = productsConverter(data);
        this.searchedProducts = result.products;
        // data.filteredRows.forEach((refNum) => {
        //   this.searchedProducts[refNum] = new ProductModel(
        //     data.products[refNum]
        //   );
        // });
      });
    });
  };
}

const searchStorage = new SearchStorage();

export default searchStorage;
