import axios from "axios";
import { runInAction, makeAutoObservable } from "mobx";

import { toast } from "react-toastify";
import productsConverter, {
  imageProductAdapter,
} from "./adapters/optModa/productsConverter";
import filtersStorage from "./filtersStorage";
import contentStorage from "./contentStorage";
import ProductModel from "./models/productModel";
import productAdapter from "./adapters/optModa/productAdapter";
import ImageModel from "./models/imageModel";
import logger from "../helpers/logger";
import urls from "./api/urls";
import requestPost from "../api/requestPost";
import parallerProcessor from "../core/api/parallerProcessor";
import newAndSalesApiProcessor from "../core/api/newAndSalesApiProcessor";

import productsForCompare from "../mocks/compareProductsList";

class ProductsListStorage {
  agregatedProducts = [];

  products = {};

  productsList = [];

  newProductsList = [null, null, null, null, null, null, null, null];

  specialProductsList = [null, null, null, null, null, null, null, null];

  featuredProductsList = [null, null, null, null, null, null, null, null];

  currentPage = 0;

  pageSize = 24;

  total = 0;

  loading = false;

  cartItems = {};

  isLoaded = false;

  lastLoad = null;

  featuredProductsIdList = [];

  compareProductsIdList = [];

  compareProductsCount = 0;

  featuredListVerificationStatus = 0;

  comparingProducts = [];

  featuredListVerificationMessage = " ";

  constructor() {
    this.fillProductList();
    this.comparingProducts = productsForCompare;
    makeAutoObservable(this);
  }

  get productsForCompare() {
    return this.comparingProducts;
  }

  get startPosition() {
    return this.currentPage * this.pageSize;
  }

  get endPosition() {
    return (this.currentPage + 1) * this.pageSize;
  }

  get agregatedTotal() {
    return this.agregatedProducts?.length || 0;
  }

  loadProducts = (inChain, cb, useStart = false) => {
    if (!this.loading) {
      // this.productsList = [];
      const data = {
        pagesize: this.pageSize,
        start: this.currentPage * this.pageSize,
        filters: filtersStorage.remoteFilters,
        useStart: this.currentPage > 0,
      };
      if (filtersStorage.orderby) {
        data.orderby = filtersStorage.orderby;
      }
      const lastload = JSON.stringify(data);
      if (this.lastLoad === lastload) return;
      this.lastLoad = lastload;
      this.fillProductList();
      this.loading = true;
      this.isLoaded = false;
      axios
        .post(`${urls.baseUrl}/api/products/initial`, data)
        // .post(`${urls.baseUrl}/api/products/initial/optimized`, data)
        // .post(`${urls.baseUrl}/api/products/load`, data)
        .then((response) => {
          runInAction(() => {
            this.productsList = [];
            this.addProducts(response);
            this.loading = false;
            filtersStorage.updateDictionaries(response?.data?.dictionaries);
            // contentStorage.setContent(response?.data?.content);
            if (response?.data?.priceRange) {
              if (filtersStorage.priceFilter.max === filtersStorage.maxPrice) {
                filtersStorage.priceFilter.max =
                  response.data.priceRange.maxPrice / 100;
              }
              if (filtersStorage.priceFilter.min === filtersStorage.minPrice) {
                filtersStorage.priceFilter.min =
                  response.data.priceRange.minPrice / 100;
              }
              filtersStorage.maxPrice = response.data.priceRange.maxPrice / 100;
              filtersStorage.minPrice = response.data.priceRange.minPrice / 100;
            }
            this.isLoaded = true;
          });
          if (cb) cb(null, true);
        });
    } else if (cb) cb(null, true);
  };

  loadProductsNext = (inChain, cb) => {
    // console.log("AST");
    if (!this.loading) {
      this.loading = true;
      this.isLoaded = false;
      const data = {
        pagesize: this.pageSize,
        start: this.currentPage * this.pageSize,
        filters: filtersStorage.remoteFilters,
      };
      axios
        // .post(`${urls.baseUrl}/api/products/initial`, data)
        .post(`${urls.baseUrl}/api/products/load`, data)
        .then((response) => {
          runInAction(() => {
            this.addProducts(response);
            this.loading = false;
            this.isLoaded = true;
          });
          if (cb) cb(null, true);
        });
    } else if (cb) cb(null, true);
  };

  clearLoading = () => {
    this.loading = false;
  };

  loadSingleProduct = (id, inChain, cb) => {
    logger.log("START LOAD SINGLE PRODUCT", this.loading);
    if (!this.loading) {
      this.loading = true;
      axios
        .get(`${urls.baseUrl}/api/v7/products/single/${id}`)
        .then((response) => {
          runInAction(() => {
            const products = response?.data?.searchResult;
            const product = response?.data?.searchResult[id];
            const mainProduct = new ProductModel();
            productAdapter(product, mainProduct);
            // const ost = {};
            products[id].subitems2.forEach((item) => {
              const childProduct = new ProductModel();
              productAdapter(item, childProduct);
              if (item.EtoNabor == 0) {
                mainProduct.addChild(childProduct);
              } else {
                mainProduct.childProducts.push(childProduct);
              }
              this.products[childProduct.refNum] = childProduct;
              // ost[item.KodVnutrennii] = item;
            });
            products[id].images.forEach((image) => {
              const newImage = new ImageModel();
              imageProductAdapter(newImage, image);
              mainProduct.addImage(newImage);
              if (image.IsMainImage == "1") {
                mainProduct.mainImage = newImage;
              }
            });
            products[id].imagesBrandColor.forEach((image) => {
              const newImage = new ImageModel();
              imageProductAdapter(newImage, image);
              mainProduct.addColorImage(image.CvetHar, newImage);

              const child = image.KodVnutrennii;
              const parent = this.products[child];
              // console.log("SINGLE PRODUCT", parent, child);
              if (parent && parent.refNum !== id) {
                if (!parent.mainImage && newImage.mainImage) {
                  parent.mainImage = newImage;
                  parent.addImage(newImage);
                } else {
                  parent.addImage(newImage);
                }
              }
            });
            this.products[id] = mainProduct;
            // const result = productsConverter({
            //   products,
            //   images: products[id].images,
            //   ost,
            // });
            // this.products[id] = result.products[id];
            // console.log("SINGLE PRODUCT", result.products);
            // Object.keys(result.products).forEach((refNum) => {
            //   if (refNum !== id) {
            //     this.products[refNum] = result.products[refNum];
            //     this.products[id].addChild(result.products[refNum]);
            //   }
            // });
            // this.addProducts(response);
            this.loading = false;
            // console.log("SINGLE PRODUCT", this.products);
          });
          if (cb) cb(null, true);
        });
    } else if (cb) cb("Already loading...", "Already loading...");
  };

  addNewAndSales = (serverData) => {
    debugger;
    const result = productsConverter(serverData?.data);
    console.log("LOADING", result);
    this.products = { ...this.products, ...result.products };
    this.newProductsList = serverData?.data?.newProductsList || [];
    this.specialProductsList = serverData?.data?.saleProductsList || [];
    contentStorage.setContent(serverData?.data?.content);
  };

  addProducts = (serverData) => {
    // this.products = {};
    // this.newProductsList = [];
    // this.specialProductsList = [];
    // this.featuredProductsList = [];
    const result = productsConverter(serverData?.data);
    this.products = { ...this.products, ...result.products };
    this.productsList = result.productsList || [];
    if (
      this.newProductsList.length === 0 &&
      this.productsList &&
      this.productsList.length > 0
    ) {
      this.newProductsList = this.productsList.filter(
        (refNum) =>
          this.products[refNum].badges.newItem &&
          // eslint-disable-next-line eqeqeq
          (this.products[refNum].parent == 0 ||
            this.products[refNum].parent == null)
      );
    }
    if (
      this.specialProductsList.length === 0 &&
      this.productsList &&
      this.productsList.length > 0
    ) {
      this.specialProductsList = this.productsList.filter(
        (refNum) =>
          !this.newProductsList.includes(refNum) &&
          this.products[refNum].badges.onSale &&
          // eslint-disable-next-line eqeqeq
          (this.products[refNum].parent == 0 ||
            this.products[refNum].parent == null)
      );
    }
    if (this.featuredProductsList.length === 0) {
      this.featuredProductsList = Object.keys(this.products).filter(
        (refNum) => this.products[refNum].badges.hot
      );
    }
    if (serverData?.data?.total?.count) {
      this.total = serverData?.data?.total?.count;
    }

    // const cart = serverData?.data?.cart;
    // if (cart) {
    //   shoppingCartStorage.setCart(cart);
    // }
  };

  loadRealProducts = () => {
    const range = this.agregatedProducts.slice(
      this.currentPage * this.pageSize,
      (this.currentPage + 1) * this.pageSize
    );
    axios
      .post(`${urls.baseUrl}/api/v7/products/real`, {
        range,
      })
      .then((response) => {
        runInAction(() => {
          this.products = {};
          this.newProductsList = [];
          this.specialProductsList = [];
          this.featuredProductsList = [];
          const result = productsConverter(response?.data);
          this.products = result.products;
          this.productsList = result.productsList;
          this.newProductsList = Object.keys(this.products).filter(
            (refNum) => this.products[refNum].badges.newItem
          );
          this.specialProductsList = Object.keys(this.products).filter(
            (refNum) => this.products[refNum].badges.onSale
          );
          this.featuredProductsList = Object.keys(this.products).filter(
            (refNum) => this.products[refNum].badges.hot
          );
          this.total = response?.data?.total?.count;
          this.loading = false;
        });
      });
  };

  // addProducts = (products, adapter) => {
  //   const ids = Object.keys(products);
  //   ids.forEach((id) => {
  //     const product = new ProductModel();
  //     adapter(products[id], product);
  //   });
  // };

  setPageSize = (value) => {
    this.pageSize = value;
    this.loadProducts();
  };

  setCategories = (categories) => {};

  changePage = (page) => {
    this.currentPage = page > 0 ? page - 1 : 0;
  };

  setPage = (page, cb) => {
    if (this.currentPage != page) {
      window.scroll(0, 0);
    }
    this.currentPage = page > 0 ? page - 1 : 0;
    if (this.currentPage > Math.floor(this.total / this.pageSize)) {
      this.currentPage = Math.floor(this.total / this.pageSize);
    }
    this.loading = false;
    if (cb) {
      this.loadProducts(true, cb, true);
    } else {
      this.loadProducts(true, null, true);
    }
  };

  lastPage = () => {
    window.scroll(0, 0);
    this.currentPage = Math.floor(this.total / this.pageSize);
    this.loadProductsNext();
  };

  // prevPage = () => {
  //   window.scroll(0, 0);
  //   if (this.currentPage > 0) {
  //     this.currentPage = 0;
  //     this.loadProductsNext();
  //   }
  // };

  prevPage = () => {
    window.scroll(0, 0);
    if (this.currentPage > 0) {
      this.currentPage -= 1;
      // this.loadProductsNext();
    }
  };

  firstPage = () => {
    window.scroll(0, 0);
    this.currentPage = 0;
    // if (this.currentPage > 0) {
    //   this.currentPage -= 1;
    // this.loadProductsNext();
    // }
  };

  goToPage = (page) => {
    window.scroll(0, 0);
    this.currentPage = page > 0 ? page - 1 : 0;
    if (this.currentPage > Math.floor(this.total / this.pageSize)) {
      this.currentPage = Math.floor(this.total / this.pageSize);
    }
    // this.loadProductsNext();
  };

  selectedPage = (value) => {
    if (this.currentPage === value - 1) return this.currentPage;
    window.scroll(0, 0);
    const exitHighBounds = Math.floor(this.total / this.pageSize) < value - 1;
    const exitLowBounds = value - 1 <= 0;

    const currentPage = exitHighBounds
      ? Math.floor(this.total / this.pageSize)
      : exitLowBounds
      ? 0
      : value - 1;
    this.currentPage = currentPage;
    // this.loadProductsNext();
    return currentPage;
  };

  setFilters = (filters, page = 0) => {
    Object.keys(filters).forEach((key) => {
      if (filtersStorage.currentFilters[key]) {
        filtersStorage.currentFilters[key] = filters[key];
      }
    });
    this.total = 0;
    this.productsList = [];
    this.setPage(page);
    // return this.loadProducts();
  };

  loadNewAndSales = (inChain, cb) => {
    debugger;
    if (!this.loading) {
      this.loading = true;
      const data = {
        limit: 8,
      };
      axios
        .post(`${urls.baseUrl}/api/v7/products/newandsales`, data)
        // .post(`${urls.baseUrl}/api/products/load`, data)
        .then((response) => {
          runInAction(() => {
            this.newProductsList = [];
            this.specialProductsList = [];
            this.addNewAndSales(response);
            // this.newProductsList = response?.data?.newProductsList;
            // this.specialProductsList = response?.data?.saleProductsList;
            this.loading = false;
            contentStorage.setContent(response?.data?.content);
            // filtersStorage.updateDictionaries(response?.data?.dictionaries);
            // contentStorage.setContent(response?.data?.content);
            if (cb) cb(null, true);
          });
        });
    } else if (cb) cb(null, true);
  };

  setCartItems = (cartItems, images) => {
    if (cartItems) {
      this.cartItems = {};
      const result = productsConverter({
        products: cartItems,
        images,
      });
      this.cartItems = result.products;
    }
  };

  fillProductList = () => {
    this.productsList = [];
    // if (this.productsList.length === 0) {
    this.productsList.length = this.pageSize;
    // }
    this.productsList.fill(null, 0, this.pageSize);
    // console.log("WREST", this.productsList);
  };

  setFeaturedProductsIdList = (text) => {
    this.featuredProductsIdList = text.split(/[\s]+/);
    this.featuredListVerificationStatus = 0;
  };

  sendFeaturedProductsForVerification = () => {
    requestPost(`${urls.baseUrl}/api/v11/products/featured/check`, {
      productsList: this.featuredProductsIdList,
    }).then((resp) => {
      toast.success("Checked");
      runInAction(() => {
        this.featuredListVerificationMessage = resp?.payload?.message;
        if (resp.status === "success") {
          this.featuredListVerificationStatus = 1;
        } else {
          this.featuredListVerificationStatus = -1;
        }
        if (resp?.payload?.realProducts) {
          this.featuredProductsIdList = resp?.payload?.realProducts;
        }
      });
    });
  };

  saveFeaturedProducts = () => {
    requestPost(`${urls.baseUrl}/api/v11/products/featured/save`, {
      productsList: this.featuredProductsIdList,
    }).then((resp) => {
      toast.success("Saved");
      const loads = [newAndSalesApiProcessor];
      parallerProcessor(loads);
    });
  };

  addProductIdToCompareList = (refNum) => {
    if (!this.compareProductsIdList.includes(refNum)) {
      this.compareProductsIdList.push(refNum);
      this.compareProductsCount += 1;
    } else {
      const index = this.compareProductsIdList.indexOf(refNum);
      if (index > -1) {
        this.compareProductsIdList.splice(index, 1);
        this.compareProductsCount -= 1;
      }
    }
  };

  clearCompareList = () => {
    this.compareProductsIdList = [];
    this.compareProductsCount = 0;
  };
}

const productsListStorage = new ProductsListStorage();

export default productsListStorage;

/*
{
  ["theme-section-header"]: document.getElementById("theme-section-header").getElementsByTagName('*'),
  MainContent: document.getElementById("MainContent").getElementsByTagName('*'),
  ["theme-section-footer"]:document.getElementById("theme-section-footer").getElementsByTagName('*'),
  ["theme-section-hero"]:document.getElementById("theme-section-hero").getElementsByTagName('*'),
  ["theme-section-1537197792650"]:document.getElementById("theme-section-1537197792650").getElementsByTagName('*'),
  ["product-block"]:document.getElementById("product-block").getElementsByTagName('*'),
  ["sorting-collections__head"]:document.getElementByClassName("sorting-collections__head").items(0).getElementsByTagName('*'),
}
*/
