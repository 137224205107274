/* eslint-disable camelcase */
import { makeAutoObservable } from "mobx";
import shoppingCartStorage from "../shoppingCartStorage";
import layoutStorage from "../layoutStorage";
import Core from "../../core/Core";

class ProductModel {
  refNum = "";

  images = [];

  title = "";

  sku = "";

  vendor = "";

  price = 0;

  oldPrice = 0;

  rrp = 0;

  colors = [];

  sizes = [];

  description = "";

  description_ru = "";

  attributes = {};

  reviews = [];

  relatedProducts = [];

  colorImages = [];

  inStock = 0;

  inPack = 1;

  minimalOrder = 1;

  parent = 0;

  mainProduct = false;

  Brand_Artikul = null;

  Brand_Artikul_Color = null;

  category_id = null;

  collection_id = null;

  colorBase_id = null;

  color_id = null;

  country_id = null;

  gender_id = null;

  group_id = null;

  konstrukciya_id = null;

  material_id = null;

  metaDescription = null;

  metaDescription_ru = null;

  metaTitle = null;

  metaTitle_ru = null;

  name = null;

  name_ru = null;

  season_id = null;

  sostavTkani_ru = null;

  uteplitel_id = null;

  badges = {
    newItem: false,
    onSale: false,
    hot: false,
  };

  childProducts = [];

  mainColor = null;

  color_sizes = {};

  color_sizes_inStock = {};

  sizes_inStock = {};

  selectedSize = null;

  selectedColor = null;

  categories = [];

  slug_ru = null;

  slug_uk = null;

  get productMetaTitle() {
    const lang = layoutStorage.currentLanguage;
    let name = "metaTitle";
    if (lang !== layoutStorage.defaultLanguage) name += `_${lang}`;
    if (this[name] !== undefined) {
      return this[name];
    }
    return null;
  }

  get productMetaDescription() {
    const lang = layoutStorage.currentLanguage;
    let name = "metaDescription";
    if (lang !== layoutStorage.defaultLanguage) name += `_${lang}`;
    if (this[name] !== undefined) {
      return this[name];
    }
    return null;
  }

  get selectedProduct() {
    const refNum =
      this.color_sizes[`${this.selectedColor}-${this.selectedSize}`];
    const product =
      this.childProducts.find((p) => p.refNum === refNum && !!p.title) || this;
    return product || this;
  }

  get productTitle() {
    const lang = layoutStorage.currentLanguage;
    let name = "name";
    if (lang !== layoutStorage.defaultLanguage) name += `_${lang}`;
    console.log("LANG productTitle", lang, name);
    const refNum =
      this.color_sizes[`${this.selectedColor}-${this.selectedSize}`];
    const product =
      this.childProducts.find((p) => p.refNum === refNum && !!p.title) || this;
    if (product[name] !== undefined) {
      return product[name];
    }
    if (this[name] !== undefined) {
      return this[name];
    }
    return null;
  }

  get productMainTitle() {
    const lang = layoutStorage.currentLanguage;
    let name = "name";
    if (lang !== layoutStorage.defaultLanguage) name += `_${lang}`;
    console.log("LANG productMainTitle", layoutStorage.currentLanguage, name);
    if (this[name] !== undefined) {
      return this[name];
    }
    return null;
  }

  get currentProductTitle() {
    const lang = layoutStorage.currentLanguage;
    let name = "name";
    if (lang !== layoutStorage.defaultLanguage) name += `_${lang}`;
    console.log(
      "LANG currentProductTitle",
      layoutStorage.currentLanguage,
      name
    );
    if (this[name] !== undefined) {
      return this[name];
    }
    return null;
  }

  get currentImages() {
    return this.colorImages[`${this.selectedColor}`];
  }

  get currentDescription() {
    return this.description_ru;
  }

  get inCart() {
    const refNum =
      this.color_sizes[`${this.selectedColor}-${this.selectedSize}`];
    return shoppingCartStorage.items[refNum] !== undefined;
  }

  get haveInStock() {
    const available =
      this.color_sizes_inStock[`${this.selectedColor}-${this.selectedSize}`];
    return available > 0;
  }

  get currentInStock() {
    const available =
      this.color_sizes_inStock[`${this.selectedColor}-${this.selectedSize}`];
    return available;
  }

  get currentRefNum() {
    const refNum =
      this.color_sizes[`${this.selectedColor}-${this.selectedSize}`];
    return refNum;
  }

  get allowedAttributes() {
    const allowedFields = [
      "title",
      "mainColor",
      "gender",
      "country",
      "sostav",
      "material",
      "season",
      "osobennost",
      "uteplitel",
    ];
    return Object.keys({
      // ...this.attributes,
      sku: this.sku,
      [`name_${layoutStorage.currentLanguage}`]: this.title,
      mainColor: this.attributes.mainColor,
      gender: this.attributes.gender,
      mainSize: this.attributes.mainSize,
      countryOfProduction: this.attributes.countryOfProduction,
      composition: this.attributes.composition,
      material: this.attributes.material,
      season: this.attributes.season,
      feature: this.attributes.feature,
      insulation: this.attributes.insulation,
    });
  }

  get relativeLink() {
    const titleText = this.selectedProduct.productTitle;
    const selectedProductRefNum = this.selectedProduct.refNum;
    let slug = "";
    if (this.selectedProduct[`slug_${layoutStorage.currentLanguage}`]) {
      slug = this.selectedProduct[`slug_${layoutStorage.currentLanguage}`];
    }
    return Core.routing.createRoute(
      Core.routing.routingSegments.SEGMENT_PRODUCT,
      {
        slug: `${slug}/${this.parent || this.refNum}/${selectedProductRefNum}`,
        title: "",
        // slug: this.refNum,
        // title: this.title,
        // gender: this.attributes.gender,
        // vendor: this.vendor,
      }
    );
  }

  // get productDetailsLink() {
  //   const refNum = this._parent || this.refnum;
  //   selectedProductRefNum =
  //   return Core.routing.createRoute(
  //     Core.routing.routingSegments.SEGMENT_PRODUCT,
  //     {
  //       slug: `${refNum}/${selectedProductRefNum}`,
  //       title: titleText,
  //       // title: product[`name${layoutStorage.currentLanguage === 'uk' ? "" : `_${layoutStorage.currentLanguage}`}`],
  //       // title: product.productMainTitle,
  //       // gender,
  //       // vendor,
  //       // refNum: selectedProductRefNum
  //     }
  //   );
  // }

  get jsonLdProduct() {
    return {};
  }

  get jsonLdProductItemList() {
    return {
      "@type": "ListItem",
      position: 1,
      url: `https://${window.location.hostname}${this.relativeLink}`,
    };
  }

  constructor(product, observable = true) {
    if (product) {
      Object.keys(product).forEach((key) => {
        if (product[key]) {
          this[key] = product[key];
        }
      });
    }
    this.selectedSize = 0;
    this.selectedColor = 0;
    if (observable) {
      makeAutoObservable(this);
    }
  }

  setField = (field, value) => {
    this[field] = value;
  };

  placeToCart = () => {
    const refNum =
      this.color_sizes[`${this.selectedColor}-${this.selectedSize}`];
    shoppingCartStorage.placeToCart(refNum);
  };

  get variants() {
    return this.childProducts.filter((p) => p.mainProduct);
  }

  addChild = (child) => {
    this.childProducts.push(child);
    if (!this.colors.includes(child.mainColor)) {
      this.colors.push(child.mainColor);
    }
    this.sizes_inStock[child.attributes.mainSize] = child.inStock;
    this.color_sizes[`${child.mainColor}-${child.attributes.mainSize}`] =
      child.refNum;
    this.color_sizes_inStock[
      `${child.mainColor}-${child.attributes.mainSize}`
    ] = child.inStock;

    if (!this.sizes.includes(child.attributes.mainSize))
      this.sizes.push(child.attributes.mainSize);
    if (!this.selectedSize) {
      this.selectedSize = child.attributes.mainSize;
    }
  };

  selectColor = (color) => {
    this.selectedColor = color;
  };

  addImage = (newImage) => {
    const alreadyHave =
      this.images.findIndex(
        (image) => newImage.bigImageURL === image.bigImageURL
      ) >= 0;
    if (!alreadyHave) {
      this.images.push(newImage);
    }
  };

  addColorImage = (color, newImage) => {
    // console.log(
    //   "SINGLE PRODUCT ADD COLOR IMAGE",
    //   color,
    //   newImage,
    //   this.colorImages
    // );
    let alreadyHave = false;
    if (!this.colorImages[color]) {
      this.colorImages[color] = [];
    } else {
      alreadyHave =
        this.colorImages[color].findIndex(
          (image) => newImage.bigImageURL === image.bigImageURL
        ) >= 0;
    }
    if (!alreadyHave) {
      this.colorImages[color].push(newImage);
    }
  };
}

export default ProductModel;
