class NovaPoshtaTownModel {
  AddressDeliveryAllowed = true;

  Area = null; // "Київська"

  DeliveryCity = null; // "8d5a980d-391c-11dd-90d9-001a92567626"

  MainDescription = null; // "Київ"

  ParentRegionCode = null; // "обл."

  ParentRegionTypes = null; // "область"

  Present = null; // "м. Київ, Київська обл."

  Ref = null; // "e718a680-4b33-11e4-ab6d-005056801329"

  Region = null;

  RegionTypes = null;

  RegionTypesCode = null;

  SettlementTypeCode = null; // "м."

  StreetsAvailability = true;

  Warehouses = 0;

  constructor(data) {
    Object.keys(this).forEach((field) => {
      if (data[field] !== undefined) {
        this[field] = data[field];
      }
    });
  }
}

export default NovaPoshtaTownModel;
