/* eslint-disable no-param-reassign,eqeqeq */
import ProductModel from "../../models/productModel";
import productAdapter from "./productAdapter";
import ImageModel from "../../models/imageModel";
import productsListStorage from "../../productsListStorage";

export function imageProductAdapter(image, original) {
  image.width = +original.width;
  image.height = +original.height;
  image.altText = original.altText;
  image.mainImage = original.IsMainImage == 1;
  image.originalImageURL = `https://pics.ua.moda/max/${encodeURI(
    original.imageURL
  ).replace(/%5C/g, "/")}`;
  image.bigImageURL = original.md
    ? `https://loader.opt.moda/assets/images/900/${original.md}.jpg`
    : `https://pics.ua.moda/max/${encodeURI(original.imageURL).replace(
        /%5C/g,
        "/"
      )}`;
  image.smallImageUrl = original.md
    ? `https://loader.opt.moda/assets/images/150/${original.md}.jpg`
    : image.bigImageURL;
  image.middleImageUrl = original.md
    ? `https://loader.opt.moda/assets/images/300/${original.md}.jpg`
    : image.bigImageURL;
  if (original.md) {
    image.srcSet = [
      {
        width: 150,
        url: `https://loader.opt.moda/assets/images/150/${original.md}.jpg`,
      },
      {
        width: 300,
        url: `https://loader.opt.moda/assets/images/300/${original.md}.jpg`,
      },
      {
        width: 600,
        url: `https://loader.opt.moda/assets/images/600/${original.md}.jpg`,
      },
      {
        width: 900,
        url: `https://loader.opt.moda/assets/images/900/${original.md}.jpg`,
      },
      {
        width: 1200,
        url: `https://loader.opt.moda/assets/images/1200/${original.md}.jpg`,
      },
    ];
  }
}
function childProductAdapter(child, parentProduct) {
  const newProduct = new ProductModel();
  productAdapter(child, newProduct);
  parentProduct.childProducts.push(newProduct);
  if (!parentProduct.colors.includes(newProduct.mainColor)) {
    parentProduct.colors.push(newProduct.mainColor);
  }
  parentProduct.sizes_inStock[newProduct.attributes.mainSize] =
    newProduct.inStock;
  parentProduct.color_sizes[
    `${newProduct.mainColor}-${newProduct.attributes.mainSize}`
  ] = newProduct.refNum;
  parentProduct.color_sizes_inStock[
    `${newProduct.mainColor}-${newProduct.attributes.mainSize}`
  ] = newProduct.inStock;

  if (!parentProduct.sizes.includes(newProduct.attributes.mainSize))
    parentProduct.sizes.push(newProduct.attributes.mainSize);
  if (!parentProduct.selectedSize && newProduct.inStock > 0) {
    parentProduct.selectedSize = newProduct.attributes.mainSize;
  }
  return newProduct;
}

function productsConverter(serverData) {
  const result = {
    products: {},
    productsList: [],
    cartItems: {},
  };

  const products = serverData?.products;
  const childProducts = serverData?.ost;
  const images = serverData?.images;
  const cartProducts = serverData?.cartItems;
  if (products) {
    Object.keys(products).forEach((productId) => {
      const newProduct = productsListStorage.products[productId]
        ? productsListStorage.products[productId]
        : new ProductModel();
      productAdapter(products[productId], newProduct);
      result.products[newProduct.refNum] = newProduct;
    });
    result.productsList = serverData?.filteredRows;
  }
  if (childProducts) {
    Object.keys(childProducts).forEach((childProductKey) => {
      if (productsListStorage.products[childProductKey]) return;
      const childProduct = childProducts[childProductKey];
      // eslint-disable-next-line no-underscore-dangle
      const parent = childProduct._parent;
      const parentProduct = result.products[parent];
      if (parentProduct) {
        const newChildProduct = childProductAdapter(
          childProduct,
          parentProduct
        );
        parentProduct.inStock += newChildProduct.inStock;
        result.products[newChildProduct.refNum] = newChildProduct;
      }
    });
  }
  if (images) {
    images.forEach((image) => {
      const parentProduct = result.products[image.KodVnutrennii];
      if (parentProduct) {
        const newImage = new ImageModel();
        imageProductAdapter(newImage, image);
        parentProduct.addImage(newImage);
        if (image.IsMainImage == "1") {
          parentProduct.mainImage = newImage;
        }
      }
    });
  }
  if (cartProducts) {
    Object.keys(cartProducts).forEach((productId) => {
      const newProduct = new ProductModel();
      productAdapter(cartProducts[productId], newProduct);
      result.cartItems[newProduct.refNum] = newProduct;
    });
  }
  return result;
}

export default productsConverter;
