import { makeAutoObservable } from "mobx";
import PromoboxModel from "./models/PromoboxModel";

class PromosStorage {
  currentPromos = [];

  constructor() {
    for (let i = 0; i < 10; i += 1) {
      const newPromo = new PromoboxModel({
        link: "",
        linkText: "fsajlfj aflja lsd",
        mainImage: "",
        texts: ["as;lfdkas asdf", "sdaaadasdf ", "asdasdfsdgsdg", "adrgrdgrdg"],
      });
      this.currentPromos.push(newPromo);
    }
    makeAutoObservable(this);
  }
}

const promosStorage = new PromosStorage();

export default promosStorage;
