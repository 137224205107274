import { makeAutoObservable } from "mobx";

class ServicePageStorage {
  title = "Support";

  mainText =
    'Have a question? You may find an answer in our <a href="pages/answers-to-your-questions" class="btn-link">FAQs</a>.<br />But you can\n also contact us:';

  constructor() {
    makeAutoObservable(this);
  }
}

const servicePageStorage = new ServicePageStorage();

export default servicePageStorage;
