import { makeAutoObservable } from "mobx";
import MenuModel from "./MenuModel";

class PromoboxModel {
  mainImage = null;

  alternativeImage = null;

  imageAlt = null;

  texts = [];

  buttons = [];

  shadowColor = "";

  textStroke = "";

  textStrokeWidth = 1;

  shadowDistance = "4";

  shadowBlur = "4";

  allowTextShadow = false;

  allowTextStroke = false;

  allowDateAndTimeOfPromotion = false;

  dateOfPromotion = null;

  enabled = true;

  outlineType = "";

  allowLeftOnMobile = false;

  linkText = null;

  link = null;

  constructor(data) {
    if (data) {
      Object.keys(this).map((fieldName) => {
        if (data[fieldName] !== undefined) {
          this[fieldName] = data[fieldName];
        }
      });
      if (!data.mainImage && data.image) {
        this.mainImage = data.image;
      }
      if (data.buttons) {
        this.buttons = data.buttons.map((b) => new MenuModel(b));
      }
      if (this.dateOfPromotion) {
        this.dateOfPromotion = new Date(this.dateOfPromotion);
      }
    }
    makeAutoObservable(this);
  }

  changeAllowTextShadow = () => (this.allowTextShadow = !this.allowTextShadow);

  changeAllowTextStroke = () => (this.allowTextStroke = !this.allowTextStroke);

  changeShadowColor = (color) => (this.shadowColor = color);

  changeShadowDistance = (distance) => (this.shadowDistance = distance);

  changeShadowBlur = (blur) => (this.shadowBlur = blur);

  changeTextStroke = (textStroke) => (this.textStroke = textStroke);

  changeTextStrokeWidth = (textStrokeWidth) =>
    (this.textStrokeWidth = textStrokeWidth);

  changePromoText = (index, text) => (this.texts[index] = text);

  changePromoLinkText = (text) => (this.linkText = text);

  changePromoLink = (link) => (this.link = link);

  changeDateOfPromotion = (dateOfPromotion) => {
    this.dateOfPromotion = dateOfPromotion;
  };

  changeAllowDateAndTimeOfPromotion = () => {
    this.allowDateAndTimeOfPromotion = !this.allowDateAndTimeOfPromotion;
  };

  toggleHomePromotion = () => (this.enabled = !this.enabled);
}

export default PromoboxModel;
