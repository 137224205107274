class NovaPoshtaStreetModel {
  Description = null;

  ID = null;

  Number = null;

  Ref = null;

  constructor(data) {
    Object.keys(this).forEach((field) => {
      if (data[field] !== undefined) {
        this[field] = data[field];
      }
    });
  }
}

export default NovaPoshtaStreetModel;
