import { makeAutoObservable } from "mobx";
import Core from "../../core/Core";

class MenuModel {
  title = null;

  link = null;

  type = null;

  enabled = null;

  segment = null;

  slug = null;

  html = null;

  subtitle = null;

  icon = null;

  constructor(menu, full = true) {
    if (menu) {
      this.title = menu.title || "";
      this.slug = menu.slug || "";
      this.link =
        menu.link ||
        Core.routing.createRoute(menu.segment, {
          slug: menu.slug,
          full,
        });
      this.type = menu.type || "link";
      this.enabled = menu.enabled || false;
      this.segment = menu.segment || null;
      this.html = menu.html || null;
      this.icon = menu.icon || null;
    }
    makeAutoObservable(this);
  }

  changeField = (fieldName, text) => (this[fieldName] = text);
}

export default MenuModel;
