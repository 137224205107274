import { makeAutoObservable } from "mobx";

class WishlistStorage {
  wishes = {};

  toggleLikeStatus = (refNum) => {
    this.wishes[refNum] = !this.wishes[refNum];
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const wishlistStorage = new WishlistStorage();

export default wishlistStorage;
