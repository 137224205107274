class PaymentMethodModel {
  ID = null;

  additional = [];

  allow = false;

  integration = null;

  name = null;

  type = 2;

  constructor(method) {
    if (method) {
      Object.keys(this).forEach((field) => {
        if (method[field] !== undefined) {
          this[field] = method[field];
        }
      });
      this.ID = +this.ID;
      this.allow = this.allow === "1";
      this.type = +this.type;
      this.additional = this.additional.map((v) => +v);
    }
  }
}

export default PaymentMethodModel;
