import { makeAutoObservable } from "mobx";
import currentOrderStorage from "../orderStorage";

class UserOrderModel {
  IP = null;

  b24Code = null;

  byManager = null;

  cost = null;

  domain = null;

  items = {};

  orderDate = null;

  orderID = null;

  owner = null;

  paymentDetails = null;

  paymentType = null;

  prim = "";

  reserved = null;

  shippingDetails = null;

  shippingType = 0;

  status = 0;

  userID = null;

  get deliveryCost() {
    if (this.shippingType == 1) return 0;
    if (this.shippingType == 2 && this.cost < 50000) return 2900;
    if (this.shippingType == 3 && this.cost < 100000) return 4900;
    return 0;
  }

  constructor(order) {
    Object.keys(this).forEach((field) => {
      if (order[field] !== undefined) {
        this[field] = order[field];
      }
    });
    this.items = JSON.parse(this.items);
    this.paymentDetails = JSON.parse(this.paymentDetails);
    this.shippingDetails = JSON.parse(this.shippingDetails);
    this.cost = +this.cost;
    this.paymentType = +this.paymentType;
    this.shippingType = +this.shippingType;
    this.status = +this.status;
    this.userID = +this.userID;
  }
}

export default UserOrderModel;
