import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import NovaPoshtaTownModel from "./models/NovaPoshtaTownModel";
import NovaPoshtaWarehouseModel from "./models/NovaPoshtaWarehouseModel";
import RemoteOrderModel from "./models/RemoteOrderModel";
import shoppingCartStorage from "./shoppingCartStorage";
import NovaPoshtaStreetModel from "./models/NovaPoshtaStreetModel";
import urls from "./api/urls";
import currentUserStorage from "./currentUserStorage";

class OrderStorage {
  status = 0;

  comment = "";

  deliveryType = 0;

  paymentType = 0;

  phone = "";

  name = "";

  secondname = "";

  soname = "";

  email = "";

  deliveryTown = {
    id: null,
    input: "",
    name: null,
    refNum: null,
    Region: null,
    Area: null,
    City: null,
  };

  deliveryWarehouse = {
    id: null,
    input: "",
    name: null,
    number: null,
  };

  deliveryStreet = {
    id: null,
    input: "",
    name: null,
    number: null,
  };

  deliveryBuilding = null;

  deliveryCorp = null;

  deliveryFlat = null;

  deliveryTypes = [];

  paymentTypes = [];

  allowedDeliveryTowns = [];

  allowedDeliveryWarehouses = [];

  allowedDeliveryStreets = [];

  minTotalForFreeShipping = 500;

  validateErrors = {
    name: "",
    phone: "",
    registerPhone: "",
    loginPhone: "",
    secondname: "",
    soname: "",
    email: "",
    deliveryType: "",
    paymentType: "",
    deliveryTown: "",
    deliveryWarehouse: "",
    deliveryStreet: "",
    deliveryBuilding: "",
    deliveryFlat: "",
  };

  get isReady() {
    const ready = true;
    // console.log(
    //   "DELIVERY: ",
    //   this.deliveryType,
    //   this.paymentType,
    //   this.name,
    //   this.phone,
    //   this.soname,
    //   this.email,
    //   this.secondname,
    //   this.deliveryTown,
    //   this.deliveryWarehouse,
    //   this.deliveryStreet,
    //   this.deliveryBuilding,
    //   this.deliveryFlat,
    //   JSON.parse(JSON.stringify(this))
    // );
    if (this.deliveryType == 0 || this.paymentType == 0) return false;
    if (!this.name || !this.phone || !this.soname || !this.email) return false;
    switch (this.currentDeliveryMethod.integration) {
      case "novaposhta:warehouse":
        // if (!this.secondname) return false;
        if (!this.deliveryTown.id) return false;
        if (!this.deliveryWarehouse) return false;
        break;
      case "novaposhta:address":
        if (!this.secondname) return false;
        if (!this.deliveryTown.id) return false;
        if (!this.deliveryStreet.id) return false;
        if (!this.deliveryBuilding) return false;
        if (!this.deliveryFlat) return false;
        break;
      default:
    }
    if (!this.email.match(/[^@]+@[^\.]+\..*/)) return false;
    return ready;
  }

  get currentDeliveryMethod() {
    const deliveryMethod = this.deliveryTypes.find(
      (m) => m.ID === this.deliveryType
    );
    return deliveryMethod || { integration: null };
  }

  get allowedWarehouses() {
    return this.allowedDeliveryWarehouses
      .filter(
        (warehouse) =>
          warehouse.Number.startsWith(this.deliveryWarehouse.input) ||
          warehouse.Description.includes(this.deliveryWarehouse.input)
      )
      .slice(0, 10);
  }

  get allowedTowns() {
    return this.allowedDeliveryTowns.slice(0, 10);
  }

  get deliveryStreets() {
    return this.allowedDeliveryStreets
      .filter((str) =>
        str.Description.toLowerCase().includes(
          this.deliveryStreet.input.toLowerCase()
        )
      )
      .slice(0, 10);
  }

  constructor() {
    makeAutoObservable(this);
  }

  get allowedPaymentMethods() {
    if (this.deliveryType) {
      return this.paymentTypes.filter((pt) =>
        pt.additional.includes(this.deliveryType)
      );
    }
    return [];
  }

  validateField = (field, value) => {
    if (
      (field === "name" && !value) ||
      (field === "soname" && !value) ||
      (field === "phone" && !value) ||
      (field === "email" && !value)
    ) {
      this.validateErrors[field] = `Please enter your ${field}`;
    } else this.validateErrors[field] = "";
    if (field === "email" && value) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(value)) {
        this.validateErrors[field] = "Please enter VALID email address";
      }
    }
    if (field === "phone" && value) {
      const pattern = new RegExp(/^\+[0-9]{12}$/);
      if (!pattern.test(value)) {
        this.validateErrors[field] = "Please enter VALID phone number";
      }
    }
    if (field === "registerPhone" && value) {
      const pattern = new RegExp(/^\+[0-9]{12}$/);
      if (!pattern.test(value)) {
        this.validateErrors[field] = "Please enter VALID phone number";
      }
    }
    if (field === "loginPhone" && value) {
      const pattern = new RegExp(/^\+[0-9]{12}$/);
      if (!pattern.test(value)) {
        this.validateErrors[field] = "Please enter VALID phone number";
      }
    }
    if (this.currentDeliveryMethod.integration === "novaposhta:address") {
      this.validateErrors.secondname = this.secondname
        ? ""
        : `Please enter your secondname`;
    } else {
      this.validateErrors.secondname = "";
    }
    if (
      this.currentDeliveryMethod.integration === "novaposhta:warehouse" ||
      this.currentDeliveryMethod.integration === "novaposhta:address"
    ) {
      this.validateErrors.deliveryTown = this.deliveryTown.id
        ? ""
        : "Please select delivery town";
    } else {
      this.validateErrors.deliveryTown = "";
    }
    if (
      this.currentDeliveryMethod.integration === "novaposhta:address" &&
      this.validateErrors.deliveryTown === ""
    ) {
      this.validateErrors.deliveryStreet = this.deliveryStreet.id
        ? ""
        : `Please select delivery street`;
    } else {
      this.validateErrors.deliveryStreet = "";
    }
    if (
      this.currentDeliveryMethod.integration === "novaposhta:address" &&
      this.validateErrors.deliveryTown === "" &&
      this.validateErrors.deliveryStreet === ""
    ) {
      this.validateErrors.deliveryBuilding = this.deliveryBuilding
        ? ""
        : `Please enter delivery building`;
    } else {
      this.validateErrors.deliveryBuilding = "";
    }
    if (
      this.currentDeliveryMethod.integration === "novaposhta:address" &&
      this.validateErrors.deliveryTown === "" &&
      this.validateErrors.deliveryStreet === ""
    ) {
      this.validateErrors.deliveryFlat = this.deliveryFlat
        ? ""
        : `Please enter delivery flat`;
    } else {
      this.validateErrors.deliveryFlat = "";
    }
    if (this.deliveryType === 0) {
      this.validateErrors.deliveryType = "Please select delivery type";
    }
    if (this.deliveryType !== 0 && this.paymentType === 0) {
      this.validateErrors.paymentType = "Please select payment type";
    }
    if (
      this.currentDeliveryMethod.integration === "novaposhta:warehouse" &&
      this.validateErrors.deliveryTown === ""
    ) {
      this.validateErrors.deliveryWarehouse = this.deliveryWarehouse.id
        ? ""
        : "Please select delivery warehouse";
    } else {
      this.validateErrors.deliveryWarehouse = "";
    }
  };

  changeField = (field, value) => {
    // console.log("TRY TO CHANGE", field, value);
    if (this[field] !== undefined && value !== undefined) {
      this[field] = value;
    }
    this.validateField(field, value);
  };

  loadAllowedDeliveryTowns = (townPartText) => {
    axios
      .post(`${urls.baseUrl}/api/v3/integrations/novaposhta/getTowns`, {
        text: townPartText,
        Warehouse: 1,
      })
      .then((resp) => {
        if (resp?.data.np4) {
          runInAction(() => {
            this.allowedDeliveryTowns = [];
            resp?.data.np4.forEach((town) => {
              const newTown = new NovaPoshtaTownModel(town);
              this.allowedDeliveryTowns.push(newTown);
            });
          });
        }
      });
  };

  loadAllowedDeliveryWarehouses = () => {
    axios
      .post(`${urls.baseUrl}/api/v3/integrations/novaposhta/getWarehouses`, {
        CityRef: this.deliveryTown.id,
      })
      .then((resp) => {
        // console.log(resp.data);
        if (resp?.data?.np?.data) {
          runInAction(() => {
            this.allowedDeliveryWarehouses = [];
            resp.data.np.data.forEach((warehouse) => {
              const newWarehouse = new NovaPoshtaWarehouseModel(warehouse);
              this.allowedDeliveryWarehouses.push(newWarehouse);
            });
          });
        }
        // if (resp?.data.np4) {
        //   this.allowedDeliveryTowns = [];
        //   resp?.data.np4.forEach((town) => {
        //     const newTown = new NovaPoshtaTownModel(town);
        //     this.allowedDeliveryTowns.push(newTown);
        //   });
        // }
      });
  };

  loadAllowedDeliveryStreets = () => {
    axios
      .post(`${urls.baseUrl}/api/v3/integrations/novaposhta/getStreets`, {
        CityRef: this.deliveryTown.id,
        text: this.deliveryStreet.input,
      })
      .then((resp) => {
        // console.log(resp.data);
        if (resp?.data?.np?.data) {
          runInAction(() => {
            this.allowedDeliveryStreets = [];
            resp.data.np.data.forEach((street) => {
              const newStreet = new NovaPoshtaStreetModel(street);
              this.allowedDeliveryStreets.push(newStreet);
            });
          });
        }
        // if (resp?.data.np4) {
        //   this.allowedDeliveryTowns = [];
        //   resp?.data.np4.forEach((town) => {
        //     const newTown = new NovaPoshtaTownModel(town);
        //     this.allowedDeliveryTowns.push(newTown);
        //   });
        // }
      });
  };

  changeDeliveryTownInput = (value) => {
    this.deliveryTown.input = value;
    this.deliveryTown.input = null;
    this.deliveryTown.name = null;
    this.deliveryTown.id = null;
    this.deliveryTown.ref = null;
    this.deliveryTown.Region = null;
    this.deliveryTown.Area = null;
    this.deliveryTown.City = null;
    this.deliveryWarehouse = {
      id: null,
      input: "",
      name: null,
    };
    if (value.length > 3) {
      this.loadAllowedDeliveryTowns(value);
    }
    this.validateField("deliveryTown", this.deliveryTown.id);
  };

  changeDeliveryWarehouseInput = (value) => {
    this.deliveryWarehouse.input = value;
    this.deliveryWarehouse.id = null;
    this.deliveryWarehouse.name = null;
    this.deliveryWarehouse.number = null;
    // if (value.length > 3) {
    //   this.loadAllowedDeliveryWarehouses(value);
    // }
    this.validateField("deliveryWarehouse", this.deliveryWarehouse.id);
  };

  setDeliveryTown = (townModel) => {
    this.deliveryTown.input = townModel.Present;
    this.deliveryTown.name = townModel.Present;
    this.deliveryTown.id = townModel.DeliveryCity;
    this.deliveryTown.ref = townModel.Ref;
    this.deliveryTown.Region = townModel.Region;
    this.deliveryTown.Area = townModel.Area;
    this.deliveryTown.City = `${townModel.SettlementTypeCode} ${townModel.MainDescription}`;
    this.allowedDeliveryTowns = [];
    this.deliveryWarehouse = {
      id: null,
      input: "",
      name: null,
    };
    this.loadAllowedDeliveryWarehouses();
    this.loadAllowedDeliveryStreets();
    this.validateField("deliveryTown", townModel.DeliveryCity);
  };

  setDeliveryWarehouse = (warehouseModel) => {
    this.deliveryWarehouse.id = warehouseModel.ID;
    this.deliveryWarehouse.name = warehouseModel.Description;
    this.deliveryWarehouse.input = warehouseModel.Description;
    this.deliveryWarehouse.number = warehouseModel.Number;
    this.validateField("deliveryWarehouse", warehouseModel.id);
  };

  setDeliveryStreet = (streetModel) => {
    this.deliveryStreet.id = streetModel.ID;
    this.deliveryStreet.name = streetModel.Description;
    this.deliveryStreet.input = streetModel.Description;
    this.deliveryStreet.number = streetModel.Number;
    this.validateField("deliveryStreet", this.deliveryStreet.id);
  };

  checkout = () => {
    const order = new RemoteOrderModel();
    return axios
      .post(`${urls.baseUrl}/api/orders/create`, {
        ID: currentUserStorage.ID,
        checkoutData: order,
        mode: 0,
      })
      .then((r) => {
        // console.log(r?.data);
        if (r?.data?.orderID) {
          // Очистить корзину
          shoppingCartStorage.loadCart();
          // сохранить номер заказа
          // Показать попап с номером заказа
        }
        return r?.data?.orderID;
      });
  };

  changeDeliveryStreetInput = (value) => {
    this.deliveryStreet.input = value;
    this.deliveryStreet.id = null;
    this.deliveryStreet.name = null;
    this.deliveryStreet.number = null;
    this.loadAllowedDeliveryStreets();
    this.validateField("deliveryStreet", this.deliveryStreet.id);
  };

  changeDeliveryBuildingInput = (value) => {
    this.deliveryBuilding = value;
    this.validateField("deliveryBuilding", this.deliveryBuilding);
  };

  changeDeliveryCorpusInput = (value) => (this.deliveryCorp = value);

  changeDeliveryFlatInput = (value) => {
    this.deliveryFlat = value;
    this.validateField("deliveryFlat", this.deliveryFlat);
  };

  clearOrder = () => {
    this.phone = null;
    this.name = null;
    this.soname = null;
    this.secondname = null;
    this.email = null;
    this.status = 0;
    // this.deliveryType = 0;
    // this.paymentType = 0;
    this.deliveryTown = {
      id: null,
      input: "",
      name: null,
      refNum: null,
      Region: null,
      Area: null,
      City: null,
    };
    this.deliveryWarehouse = {
      id: null,
      input: "",
      name: null,
      number: null,
    };
    this.deliveryStreet = {
      id: null,
      input: "",
      name: null,
      number: null,
    };
    this.deliveryBuilding = null;
    this.deliveryCorp = null;
    this.deliveryFlat = null;
    // this.deliveryTypes = [];
    // this.paymentTypes = [];
    this.allowedDeliveryTowns = [];
    this.allowedDeliveryWarehouses = [];
    this.allowedDeliveryStreets = [];
  };
}

const currentOrderStorage = new OrderStorage();

export default currentOrderStorage;
