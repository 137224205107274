const baseUrls = {
  "localhost:3000": "https://kidtest.ua.moda",
  "localhost:5000": "https://kidtest.ua.moda",
  "localhost:8000": "https://kidtest.ua.moda",
  "192.168.1.13:3000": "https://kidtest.ua.moda",
  "kidtest.ua.moda": "https://kidtest.ua.moda",
  "alfakid.com": "https://alfakid.com",
  "webcache.googleusercontent.com": "https://alfakid.com",
};

// const baseUrls = {
//   "localhost:3000": "http://localhost:8000",
//   "localhost:5000": "http://localhost:8000",
//   "localhost:8000": "http://localhost:8000",
//   "192.168.1.13:3000": "http://localhost:8000",
//   "kidtest.ua.moda": "https://kidtest.ua.moda",
//   "alfakid.com": "https://alfakid.com",
//   "webcache.googleusercontent.com": "https://alfakid.com",
// };

const urls = {
  baseUrl: baseUrls[window.location.host]
    ? baseUrls[window.location.host]
    : "https://kidtest.ua.moda",
};

export default urls;
