import { makeAutoObservable } from "mobx";

class GlobalStorage {
  siteRoot = "";

  siteLogo = "https://alfakid.com/images/logo-512.png";

  organizationName = "Компания ALFAKID";

  organizationEmail = "info@alfakid.com";

  organizationStreet = "ул. Куренёвская 18";

  organizationTown = "г. Киев";

  contacts = [
    {
      phone: "+380503385663",
      type: "customer service",
    },
  ];

  socials = [
    "https://www.facebook.com/alfakid.com.ua",
    "https://www.instagram.com/alfakid.com.ua/",
  ];

  stores = [
    {
      name: "Магазин детской одежды  ALFAKID",
      logo: "https://alfakid.com/images/logo-512.png",
      workdays: [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница"],
          opens: "9:00",
          closes: "18:00",
        },
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: ["Суббота", "Воскресенье"],
          opens: "Выходной",
          closes: "Выходной",
        },
      ],
      phone: "+380444590400",
      street: "ул. Куренёвская 18",
      town: "Киев",
      country: "Украина",
    },
  ];

  constructor() {
    this.siteRoot = `https://${window.location.hostname}`;
    makeAutoObservable(this);
  }

  get jsonLdSectionWebSite() {
    return {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: this.siteRoot,
    };
  }

  get jsonLdSectionOrganization() {
    return {
      "@context": "http://schema.org",
      "@type": "Organization",
      url: this.siteRoot,
      logo: this.siteLogo,
      name: this.organizationName,
      email: this.organizationEmail,
      address: {
        "@type": "PostalAddress",
        streetAddress: this.organizationStreet,
        addressLocality: this.organizationTown,
      },
      contactPoint: this.contacts.map((organization) => ({
        "@type": "ContactPoint",
        telephone: organization.phone,
        contactType: organization.type,
      })),
      sameAs: this.socials,
    };
  }

  get jsonLdSectionStore() {
    return this.stores.map((store) => ({
      "@context": "http://schema.org",
      "@type": "Store",
      name: store.name,
      image: store.logo,
      openingHoursSpecification: store.workdays,
      telephone: store.phone,
      address: {
        "@type": "PostalAddress",
        streetAddress: store.street,
        addressLocality: store.town,
        addressCountry: store.country,
      },
      // priceRange: "4-5000 гривен",
    }));
  }
}

const globalStorage = new GlobalStorage();

export default globalStorage;
