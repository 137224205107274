import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import ProductModel from "./models/productModel";
import logger from "../helpers/logger";
import productAdapter from "./adapters/optModa/productAdapter";
import ImageModel from "./models/imageModel";
import { imageProductAdapter } from "./adapters/optModa/productsConverter";
import urls from "./api/urls";

class CurrentDetailProductStorage {
  product = null;

  loading = false;

  constructor() {
    this.product = new ProductModel();
    makeAutoObservable(this);
  }

  loadByID = (id, subID) => {
    logger.log("START LOAD SINGLE PRODUCT", this.loading);
    if (!this.loading) {
      this.loading = true;
      return axios
        .get(`${urls.baseUrl}/api/v7/products/single/${id}`)
        .then((response) => {
          runInAction(() => {
            const currentProduct = new ProductModel();
            const products = response?.data?.searchResult;
            const product = response?.data?.searchResult[id];
            productAdapter(product, currentProduct);
            product.subitems2.forEach((item) => {
              const childProduct = new ProductModel();
              productAdapter(item, childProduct);
              if (item.EtoNabor == 0) {
                currentProduct.addChild(childProduct);
              } else {
                currentProduct.childProducts.push(childProduct);
              }
              if (childProduct.refNum === subID) {
                currentProduct.selectedSize = childProduct.attributes.mainSize;
              }
            });
            product.images.forEach((image) => {
              const newImage = new ImageModel();
              imageProductAdapter(newImage, image);
              currentProduct.addImage(newImage);
              if (image.IsMainImage == "1") {
                currentProduct.mainImage = newImage;
              }
            });
            products[id].imagesBrandColor.forEach((image) => {
              const newImage = new ImageModel();
              imageProductAdapter(newImage, image);
              currentProduct.addColorImage(image.CvetHar, newImage);
            });
            this.product = currentProduct;
            this.loading = false;
          });
        });
    }

    return new Promise((resolve) => resolve(null));
  };
}

const currentDetailProductStorage = new CurrentDetailProductStorage();

export default currentDetailProductStorage;
