import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "./api/urls";
import requestPost from "../api/requestPost";

class CurrentCategoryStorage {
  texts = {
    metaTitle: "",
    metaDescription: "",
    h1: "",
    beforeAll: "",
    beforeProducts: "",
    afterProducts: "",
    ogTitle: "",
    ogDescription: "",
    ogUrl: "",
    ogImage: "",
  };

  relatedLinks = [];

  footersLinks = [
    {
      title: "top categories",
      links: [],
    },
    {
      title: "top cards",
      links: [],
    },
    {
      title: "top menu",
      links: [],
    },
    {
      title: "top filters",
      links: [],
    },
  ];

  footersLinksCount = 0;

  additionalText = null;

  categoryText = {
    metaTitle: "",
    metaDescription: "",
    h1: "",
    beforeAll: "",
    beforeProducts: "",
    afterProducts: "",
    ogTitle: "",
    ogDescription: "",
    ogUrl: "",
    ogImage: "",
  };

  faqLinks = [];

  constructor() {
    makeAutoObservable(this);
  }

  get haveFootersLinks() {
    return this.footersLinksCount > 0;
  }

  changeText(path, text) {
    this.texts[path] = text;
  }

  saveTexts = () => {
    axios
      .post(`${urls.baseUrl}/api/texts`, {
        path: window.location.pathname,
        text: this.texts,
      })
      .then((resp) => {
        toast.success("Текст сохранен");
      })
      .catch((e) => console.log(e));
  };

  clearTexts = () => {
    this.texts = {
      h1: "",
      metaTitle: "",
      metaDescription: "",
      beforeAll: "",
      beforeProducts: "",
      afterProducts: "",
      ogTitle: "",
      ogDescription: "",
      ogUrl: "",
      ogImage: "",
    };
  };

  clearCategoryTexts = () => {
    this.categoryText = {
      h1: "",
      metaTitle: "",
      metaDescription: "",
      beforeAll: "",
      beforeProducts: "",
      afterProducts: "",
      ogTitle: "",
      ogDescription: "",
      ogUrl: "",
      ogImage: "",
    };
  };

  setTexts = (texts) => {
    if (texts) {
      // this.clearTexts();
      const {
        beforeAll,
        afterAll,
        // beforeProducts,
        // afterProducts,
        h1,
        metaTitle,
        metaDescription,
        ogTitle,
        ogDescription,
        ogUrl,
        ogImage,
        ...rest
      } = texts;
      this.texts.beforeAll = beforeAll || "";
      this.texts.afterAll = afterAll || "";
      // this.texts.beforeProducts = beforeProducts || "";
      // this.texts.afterProducts = afterProducts || "";
      this.texts.h1 = h1 || "";
      this.texts.metaTitle = metaTitle || "";
      this.texts.metaDescription = metaDescription || "";
      this.texts.ogTitle = ogTitle || "";
      this.texts.ogDescription = ogDescription || "";
      this.texts.ogUrl = ogUrl || "";
      this.texts.ogImage = ogImage || "";
      Object.keys(rest).forEach((f) => {
        this.texts[f] = rest[f] || "";
      });
    }
  };

  setCategoryTexts = (texts) => {
    // this.clearTexts();
    this.categoryText.beforeAll = texts.beforeAll || "";
    this.categoryText.beforeProducts = texts.beforeProducts || "";
    this.categoryText.afterProducts = texts.afterProducts || "";
    this.categoryText.h1 = texts.h1 || "";
    this.categoryText.metaTitle = texts.metaTitle || "";
    this.categoryText.metaDescription = texts.metaDescription || "";
    this.categoryText.ogTitle = texts.ogTitle || "";
    this.categoryText.ogDescription = texts.ogDescription || "";
    this.categoryText.ogUrl = texts.ogUrl || "";
    this.categoryText.ogImage = texts.ogImage || "";
  };

  loadTexts = (path) => {
    requestPost(`${urls.baseUrl}/api/texts/single`, { path })
      // axios
      //   .post(`${urls.baseUrl}/api/texts/single`, {
      //     path,
      //   })
      .then((resp) => ({
        data: resp,
      }))
      .then((resp) => {
        runInAction(() => {
          const text = resp?.data?.text;
          const categoryText = resp?.data?.categoryText;
          const relatedLinks = resp?.data?.relatedLinks;
          const footers = resp?.data?.footers;
          const faqLinks = resp?.data?.faqText;
          const additionalText = resp?.data?.additionalText;
          this.clearRelatedLinks();
          this.clearFooters();
          this.clearCategoryTexts();
          this.clearTexts();
          this.clearAdditionalText();
          this.clearFaqLinks();
          if (additionalText) this.setAdditionalText(additionalText);
          if (text) this.setTexts(JSON.parse(text.text));
          if (categoryText)
            this.setCategoryTexts(JSON.parse(categoryText.text));
          if (relatedLinks) this.setRelatedLinks(relatedLinks);
          if (footers) this.setFooters(footers);
          if (faqLinks) this.setFaqLinks(faqLinks);
        });
      })
      .catch((e) => console.log("texts error", e));
  };

  setH1 = (text) => {
    this.texts.h1 = text;
  };

  setMetaTitle = (text) => {
    this.texts.metaTitle = text;
  };

  setMetaDescription = (text) => {
    this.texts.metaDescription = text;
  };

  setTextField = (fieldName, text) => {
    this.texts[fieldName] = text;
  };

  uploadImage = (segment, file) => {
    const url = `${urls.baseUrl}/api/v1/images/upload`;
    const data = new FormData();
    data.append("image", file);
    data.append("segment", segment);
    axios.post(url, data).then((resp) => {
      runInAction(() => {
        this.texts.ogImage = resp.data.url;
      });
    });
  };

  setRelatedLinks = (relatedLinks) => {
    this.relatedLinks = relatedLinks;
  };

  clearRelatedLinks() {
    this.relatedLinks = [];
  }

  clearAdditionalText() {
    this.additionalText = null;
  }

  setAdditionalText = (additionalText) => {
    this.additionalText = additionalText;
  };

  clearFooters() {
    this.footersLinks = [];
  }

  setFooters(footers) {
    this.footersLinksCount =
      footers.categories?.length +
      footers.cards?.length +
      footers.filters?.length;
    // console.log("footersLinksCount", this.footersLinksCount);
    this.footersLinks = [
      {
        title: "top categories",
        links: footers.categories,
      },
      {
        title: "top cards",
        links: footers.cards,
      },
      {
        title: "top menu",
        type: "multilevel",
        links: [
          {
            title: "Детская одежда",
            children: [
              { title: "детская одежда", link: "/ru/" },
              {
                title: "спортивная кофта детская",
                link: "/ru/catalog/cat-all/group-kofta-sport",
              },
              {
                title: "детская спортивная одежда",
                link: "/ru/catalog/cat-sportivnaya_odezhda",
              },
              {
                title: "бриджи детские",
                link: "/ru/catalog/cat-all/group-bridzhi",
              },
              {
                title: "штаны для подростков",
                link: "/ru/catalog/cat-all/group-bryuki",
              },
              {
                title: "ветровка детская",
                link: "/ru/catalog/cat-all/group-vetrovka",
              },
              {
                title: "водолазка детская",
                link: "/ru/catalog/cat-all/group-vodolazka",
              },
              {
                title: "жилетка детская",
                link: "/ru/catalog/cat-all/group-zhiletka",
              },
              {
                title: "капри детские",
                link: "/ru/catalog/cat-all/group-kapri",
              },
              {
                title: "комбинезон детский",
                link: "/ru/catalog/cat-all/group-kombinezon",
              },
              {
                title: "детские костюмы",
                link: "/ru/catalog/cat-all/group-kostyum",
              },
              {
                title: "кофты для подростков",
                link: "/ru/catalog/cat-all/group-kofta",
              },
              {
                title: "детские куртки",
                link: "/ru/catalog/cat-all/group-kurtka",
              },
              {
                title: "майка детская",
                link: "/ru/catalog/cat-all/group-mayka",
              },
              {
                title: "пиджаки детские",
                link: "/ru/catalog/cat-all/group-pidzhak",
              },
              {
                title: "пижама детская",
                link: "/ru/catalog/cat-all/group-pizhama",
              },
              {
                title: "детский плащ",
                link: "/ru/catalog/cat-all/group-plashch",
              },
              {
                title: "свитер детский",
                link: "/ru/catalog/cat-all/group-sviter",
              },
              {
                title: "детские спортивные штаны",
                link: "/ru/catalog/cat-all/group-bryuki-sport",
              },
              {
                title: "детские футболки",
                link: "/ru/catalog/cat-all/group-futbolka",
              },
              {
                title: "лонгсливы детские",
                link: "/ru/catalog/cat-all/group-fufayki",
              },
              {
                title: "детские халаты",
                link: "/ru/catalog/cat-all/group-khalat",
              },
              {
                title: "шорты для подростков",
                link: "/ru/catalog/cat-all/group-shorti",
              },
              {
                title: "детские джемперы",
                link: "/ru/catalog/cat-all/group-dzhemper",
              },
              {
                title: "детский спортивный костюм",
                link: "/ru/catalog/cat-all/group-kostyum-sport",
              },
            ],
          },
          {
            title: "Детская одежда для девочек",
            children: [
              {
                title: "одежда для девочек",
                link: "/ru/catalog/cat-dlya_devochki",
              },
              {
                title: "рубашки для девочек",
                link: "/ru/catalog/cat-bluzki__rubashki_dlya_devochki",
              },
              {
                title: "бриджи для девочек",
                link: "/ru/catalog/cat-bryuki__shtani_dlya_devochki/group-bridzhi",
              },
              {
                title: "штаны для девочек",
                link: "/ru/catalog/cat-bryuki__shtani_dlya_devochki/group-bryuki",
              },
              {
                title: "детский лифчик",
                link: "/ru/catalog/cat-nizhnee_bele_dlya_devochki/group-byustgalter",
              },
              {
                title: "верхняя одежда для девочек",
                link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_devochki",
              },
              {
                title: "ветровка для девочки",
                link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_devochki/group-vetrovka",
              },
              {
                title: "купить водолазку на девочку",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_devochki/group-vodolazka",
              },
              {
                title: "джемпер для девочки",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_devochki/group-dzhemper",
              },
              {
                title: "джинсовая куртка на девочку",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki/group-kurtka",
              },
              {
                title: "детская джинсовая одежда для девочек",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki",
              },
              {
                title: "джинсовая юбка для девочки",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki/group-yubka",
              },
              {
                title: "джинсовые шорты на девочку",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki/group-shorti",
              },
              {
                title: "детский джинсовый комбинезон на девочку",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki/group-kombinezon",
              },
              {
                title: "джинсовка для девочки",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki/group-pidzhak",
              },
              {
                title: "джинсовый сарафан на девочку",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki/group-sarafan",
              },
              {
                title: "джинсы для девочек",
                link: "/ru/catalog/cat-dzhinsi_dlya_devochki/group-bryuki",
              },
              {
                title: "жилетка детская на девочку",
                link: "/ru/catalog/cat-pidzhaki__zhaketi__zhiletki_dlya_devochki/group-zhiletka",
              },
              {
                title: "теплые лосины на девочку",
                link: "/ru/catalog/cat-termobele_dlya_devochki/group-kaljsoni",
              },
              {
                title: "демисезонный комбинезон на девочку",
                link: "/ru/catalog/cat-kombinezoni__polukombinezoni_dlya_devochki",
              },
              {
                title: "комплект для девочки",
                link: "/ru/catalog/cat-kostyumi_dlya_devochki/group-komplekt",
              },
              {
                title: "костюм для девочек",
                link: "/ru/catalog/cat-kostyumi_dlya_devochki",
              },
              {
                title: "кофты для девочек",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_devochki/group-kofta",
              },
              {
                title: "курточка на девочку",
                link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_devochki/group-kurtka",
              },
              {
                title: "лосины детские",
                link: "/ru/catalog/cat-shorti__kapri_dlya_devochki/group-losini",
              },
              {
                title: "топ для девочки",
                link: "/ru/catalog/cat-futbolki__topi_dlya_devochki/group-mayka",
              },
              {
                title: "нижнее белье для девочки",
                link: "/ru/catalog/cat-nizhnee_bele_dlya_devochki",
              },
              {
                title: "пуховик на девочку",
                link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_devochki/group-palto",
              },
              {
                title: "джинсовый пиджак на девочку",
                link: "/ru/catalog/cat-pidzhaki__zhaketi__zhiletki_dlya_devochki/group-pidzhak",
              },
              {
                title: "пижамы для девочек",
                link: "/ru/catalog/cat-khalati__pizhami_dlya_devochki/group-pizhama",
              },
              {
                title: "платья для девочек",
                link: "/ru/catalog/cat-platya__sarafani_dlya_devochki",
              },
              {
                title: "пляжная одежда для девочек",
                link: "/ru/catalog/cat-plyazhnaya_odezhda_dlya_devochki",
              },
              {
                title: "свитер для девочки",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_devochki/group-sviter",
              },
              {
                title: "спортивный костюм для девочки",
                link: "/ru/catalog/cat-dlya_devochki/group-kostyum-sport",
              },
              {
                title: "спортивные кофты для девочек",
                link: "/ru/catalog/cat-dlya_devochki/group-kofta-sport",
              },
              {
                title: "термобелье для девочки",
                link: "/ru/catalog/cat-termobele_dlya_devochki",
              },
              {
                title: "туника для девочки",
                link: "/ru/catalog/cat-futbolki__topi_dlya_devochki/group-tunika",
              },
              {
                title: "футболки для девочек",
                link: "/ru/catalog/cat-futbolki__topi_dlya_devochki/group-futbolka",
              },
              {
                title: "футболка с длинным рукавом на девочку",
                link: "/ru/catalog/cat-futbolki__topi_dlya_devochki/group-fufayki",
              },
              {
                title: "купить детский халат на девочку",
                link: "/ru/catalog/cat-khalati__pizhami_dlya_devochki/group-khalat",
              },
              {
                title: "шорты для девочек",
                link: "/ru/catalog/cat-shorti__kapri_dlya_devochki/group-shorti",
              },
              {
                title: "детские юбки",
                link: "/ru/catalog/cat-yubki_dlya_devochki",
              },
              {
                title: "сарафан детский",
                link: "/ru/catalog/cat-platya__sarafani_dlya_devochki/group-sarafan",
              },
            ],
          },
          {
            title: "Детская одежда для мальчиков",
            children: [
              {
                title: "одежда для мальчиков",
                link: "/ru/catalog/cat-dlya_malchika",
              },
              {
                title: "бриджи для мальчика",
                link: "/ru/catalog/cat-shorti__bridzhi_dlya_malchika/group-bridzhi",
              },
              {
                title: "брюки для мальчика",
                link: "/ru/catalog/cat-bryuki__shtani_dlya_malchika",
              },
              {
                title: "верхняя одежда для мальчиков",
                link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_malchika",
              },
              {
                title: "ветровки для мальчиков",
                link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_malchika/group-vetrovka",
              },
              {
                title: "водолазки для мальчиков",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_malchika/group-vodolazka",
              },
              {
                title: "джемпер для мальчика",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_malchika/group-dzhemper",
              },
              {
                title: "джинсовая куртка на мальчика",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika/group-kurtka",
              },
              {
                title: "джинсовая одежда для мальчиков",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika",
              },
              {
                title: "джинсовая рубашка для мальчика",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika/group-rubashka",
              },
              {
                title: "джинсовые бриджи для мальчиков",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika/group-bridzhi",
              },
              {
                title: "джинсовые шорты на мальчика",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika/group-shorti",
              },
              {
                title: "джинсовый комбинезон для мальчика",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika/group-kombinezon",
              },
              {
                title: "джинсовка для мальчика",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika/group-pidzhak",
              },
              {
                title: "джинсы на мальчика",
                link: "/ru/catalog/cat-dzhinsi_dlya_malchika/group-bryuki",
              },
              {
                title: "жилетка для мальчика",
                link: "/ru/catalog/cat-pidzhaki__zhaketi__zhiletki_dlya_malchika/group-zhiletka",
              },
              {
                title: "кальсоны для мальчиков",
                link: "/ru/catalog/cat-termobele_dlya_malchika/group-kaljsoni",
              },
              {
                title: "ромпер для мальчика",
                link: "/ru/catalog/cat-kombinezoni__polukombinezoni_dlya_malchika",
              },
              {
                title: "комплекты для мальчиков",
                link: "/ru/catalog/cat-kostyumi_dlya_malchika/group-komplekt",
              },
              {
                title: "костюм для мальчика",
                link: "/ru/catalog/cat-kostyumi_dlya_malchika",
              },
              {
                title: "кофты для мальчика",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_malchika/group-kofta",
              },
              {
                title: "курточка на мальчика",
                link: "/ru/catalog/cat-verkhnyaya_odezhda_dlya_malchika/group-kurtka",
              },
              {
                title: "майка для мальчика",
                link: "/ru/catalog/cat-nizhnee_bele_dlya_malchika/group-mayka",
              },
              {
                title: "нижнее белье для мальчика",
                link: "/ru/catalog/cat-nizhnee_bele_dlya_malchika",
              },
              {
                title: "пиджаки для мальчиков",
                link: "/ru/catalog/cat-pidzhaki__zhaketi__zhiletki_dlya_malchika/group-pidzhak",
              },
              {
                title: "пижама для мальчика",
                link: "/ru/catalog/cat-khalati__pizhami_dlya_malchika/group-pizhama",
              },
              {
                title: "детская пляжная одежда для мальчиков",
                link: "/ru/catalog/cat-plyazhnaya_odezhda_dlya_malchika",
              },
              {
                title: "рубашка для мальчика",
                link: "/ru/catalog/cat-rubashki_dlya_malchika",
              },
              {
                title: "свитер для мальчика",
                link: "/ru/catalog/cat-kofti__svitera__reglani_dlya_malchika/group-sviter",
              },
              {
                title: "спортивный костюм для мальчика",
                link: "/ru/catalog/cat-dlya_malchika/group-kostyum-sport",
              },
              {
                title: "спортивная кофта для мальчика",
                link: "/ru/catalog/cat-dlya_malchika/group-kofta-sport",
              },
              {
                title: "спортивные штаны для мальчика",
                link: "/ru/catalog/cat-dlya_malchika/group-bryuki-sport",
              },
              {
                title: "термобелье для мальчика",
                link: "/ru/catalog/cat-termobele_dlya_malchika",
              },
              {
                title: "футболки для мальчиков",
                link: "/ru/catalog/cat-futbolki__tenniski_dlya_malchika/group-futbolka",
              },
              {
                title: "футболка с длинным рукавом на мальчика",
                link: "/ru/catalog/cat-futbolki__tenniski_dlya_malchika/group-fufaiky",
              },
              {
                title: "халат для мальчика",
                link: "/ru/catalog/cat-khalati__pizhami_dlya_malchika/group-khalat",
              },
              {
                title: "шорты для мальчика",
                link: "/ru/catalog/cat-shorti__bridzhi_dlya_malchika/group-shorti",
              },
            ],
          },
          {
            title: "Одежда для малышей",
            children: [
              {
                title: "одежда для новорожденных",
                link: "/ru/catalog/cat-dlya_malishei",
              },
              {
                title: "боди майка детская",
                link: "/ru/catalog/cat-bodi__chelovechki__pesochniki_dlya_malishei/group-bodi_mayka",
              },
              {
                title: "боди футболка детская",
                link: "/ru/catalog/cat-bodi__chelovechki__pesochniki_dlya_malishei/group-bodi_futbolka",
              },
              {
                title: "комбинезон для новорожденного",
                link: "/ru/catalog/cat-kombinezoni__polukombinezoni_dlya_malishei",
              },
              {
                title: "комплекты для новорожденных",
                link: "/ru/catalog/cat-komplekti_dlya_malishei",
              },
              {
                title: "конверт для новорожденного",
                link: "/ru/catalog/cat-konverti__odeyala_dlya_malishei/group-konvert",
              },
              {
                title: "носовичок",
                link: "/ru/catalog/cat-pinetki__tsarapki__nosovichki_dlya_malishei/group-nosovoy-platok",
              },
              {
                title: "одеяло для новорожденных",
                link: "/ru/catalog/cat-konverti__odeyala_dlya_malishei/group-odeyalo",
              },
              {
                title: "пеленки",
                link: "/ru/catalog/cat-pelenki__ugolki_dlya_kupaniya_dlya_malishei/group-pelenka",
              },
              {
                title: "песочник",
                link: "/ru/catalog/cat-bodi__chelovechki__pesochniki_dlya_malishei/group-pesochnik",
              },
              {
                title: "подарочный комплект для новорожденного",
                link: "/ru/catalog/cat-komplekti_podarochnii_dlya_malishei",
              },
              {
                title: "ползунки",
                link: "/ru/catalog/cat-polzunki__raspashonki_dlya_malishei/group-polzuni",
              },
              {
                title: "полотенце уголок",
                link: "/ru/catalog/cat-pelenki__ugolki_dlya_kupaniya_dlya_malishei/group-polotentse",
              },
              {
                title: "распашонка",
                link: "/ru/catalog/cat-polzunki__raspashonki_dlya_malishei/group-raspashonka",
              },
              {
                title: "слюнявчики",
                link: "/ru/catalog/cat-pinetki__tsarapki__nosovichki_dlya_malishei/group-slyunyavchiki",
              },
              {
                title: "царапки",
                link: "/ru/catalog/cat-pinetki__tsarapki__nosovichki_dlya_malishei/group-tsarapki",
              },
              {
                title: "человечки",
                link: "/ru/catalog/cat-bodi__chelovechki__pesochniki_dlya_malishei/group-kombinezon_chelovechek",
              },
            ],
          },
          {
            title: "Аксессуары для детей",
            children: [
              {
                title: "детские аксессуары",
                link: "/ru/catalog/cat-aksessuari",
              },
              {
                title: "галстуки детские",
                link: "/ru/catalog/cat-aksessuari/group-galstuk",
              },
              {
                title: "детские рюкзаки",
                link: "/ru/catalog/cat-aksessuari/group-sumka",
              },
              {
                title: "детские перчатки",
                link: "/ru/catalog/cat-aksessuari/group-varezhki",
              },
              {
                title: "повязка на голову детская",
                link: "/ru/catalog/cat-aksessuari/group-sharfi",
              },
              {
                title: "детский ремень",
                link: "/ru/catalog/cat-aksessuari/group-remenj",
              },
            ],
          },
        ],
      },
      {
        title: "top filters",
        links: footers.filters,
      },
    ];
  }

  clearFaqLinks = () => {
    this.faqLinks = [];
  };

  setFaqLinks = (data) => {
    const faqLinks = data.products;
    if (faqLinks?.length) {
      this.faqLinks = [
        faqLinks.slice(0, faqLinks?.length / 2),
        faqLinks.slice(faqLinks?.length / 2 + 1, faqLinks?.length),
        data.brands,
      ];
    }
  };
}

const currentCategoryStorage = new CurrentCategoryStorage();

export default currentCategoryStorage;
