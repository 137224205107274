import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import CheckBoxFilterModel from "./models/CheckBoxFilterModel";
import getFilterObject from "../helpers/getFilterObject";
import PaymentMethodModel from "./models/PaymentMethodModel";
import orderStorage from "./orderStorage";
import DeliveryMethodModel from "./models/DeliveryMethodModel";
import FilterItemModel from "./models/FilterItemModel";
import urls from "./api/urls";
import attributesStorage from "./attributesStorage";
import productsListStorage from "./productsListStorage";

class FiltersStorage {
  filters = [
    {
      type: "tree",
      title: "COLLECTION",
      enabled: false,
      items: [
        {
          name: "Shop",
          count: 42,
          root: true,
        },
        {
          name: "Women's",
          count: 42,
          root: true,
          children: [
            {
              name: "Clothing",
              count: 21,
              root: false,
              children: [
                {
                  name: "Clothing",
                  count: 21,
                  root: false,
                },
                {
                  name: "Shoes",
                  count: 15,
                  root: false,
                },
                {
                  name: "Accessories",
                  count: 12,
                  root: false,
                },
              ],
            },
            {
              name: "Shoes",
              count: 15,
              root: false,
            },
            {
              name: "Accessories",
              count: 12,
              root: false,
            },
          ],
        },
        {
          name: "Men's",
          count: 42,
          root: true,
        },
      ],
      opened: true,
    },
    /** Singles */
    new CheckBoxFilterModel({
      type: "checkboxes",
      title: "New and Sales",
      columns: 1,
      field: "singles",
      items: [],
      enabled: true,
      opened: true,
    }),
    /** Цвета */
    {
      type: "colors",
      title: "COLOR",
      items: [],
      opened: true,
      enabled: false,
    },
    /** Размеры */
    new CheckBoxFilterModel({
      type: "sizes",
      title: "Sizes",
      columns: 2,
      field: "sizes",
      dontShowAppend: true,
      items: [],
      opened: true,
    }),
    /** Цена */
    new CheckBoxFilterModel({
      type: "range",
      title: "Price",
      columns: 1,
      field: "price",
      items: [],
      opened: true,
      enabled: true,
    }),
    /** Сезоны */
    new CheckBoxFilterModel({
      type: "checkboxes",
      title: "Seasons",
      columns: 1,
      field: "seasons",
      items: [],
      opened: true,
    }),
    /** Возраст */
    new CheckBoxFilterModel({
      type: "checkboxes",
      title: "Age",
      columns: 1,
      field: "vgroup",
      items: [],
      opened: true,
    }),
    /** Группа */
    new CheckBoxFilterModel({
      type: "checkboxes",
      title: "Group",
      columns: 1,
      field: "group",
      items: [],
      opened: true,
      enabled: true,
    }),
    /** Бренды */
    new CheckBoxFilterModel({
      type: "checkboxes",
      title: "Brands",
      columns: 1,
      field: "brands",
      opened: true,
      items: [],
    }),
    /** Пол */
    new CheckBoxFilterModel({
      type: "checkboxes",
      title: "Gender",
      columns: 1,
      field: "gender",
      items: [],
      enabled: false,
      opened: true,
    }),
    /** Города */
    new CheckBoxFilterModel({
      type: "checkboxes",
      title: "Cities",
      columns: 1,
      field: "cities",
      items: [],
      enabled: true,
      opened: false,
    }),
  ];

  emptyFilters = (filters = {}) => {
    this.currentFilters = {
      brands: {},
      gender: {},
      group: {},
      seasons: {},
      vgroup: {},
      singles: {
        Акції: false,
        Новинки: false,
      },
      sizes: {},
      colors: {},
      selections: 0,
      allowSalePacks: false,
      category_id: {},
      cities: {},
      ...filters,
    };
  };

  currentFilters = {
    brands: {},
    gender: {},
    group: {},
    seasons: {},
    vgroup: {},
    singles: {
      Акції: false,
      Новинки: false,
    },
    sizes: {},
    colors: {},
    selections: 0,
    allowSalePacks: false,
    category_id: {},
    cities: {},
  };

  priceFilter = {
    min: 0,
    max: 5000,
  };

  minPrice = 0;

  maxPrice = 5000;

  dictionaryLoaded = false;

  colorsMap = {};

  orderby = null;

  get rawGroups() {
    const groups = this.filters.find((f) => f.field === "group");
    return groups?.items;
  }

  get rawCurrentFilters() {
    let result = [];
    Object.keys(this.currentFilters).forEach((section) => {
      result = result.concat(
        Object.keys(this.currentFilters[section])
          .filter((k) => {
            if (section === "group" || section === "gender") return false;
            return !!this.currentFilters[section][k];
          })
          .map((k) => {
            const filterObject = getFilterObject(section, k);
            return {
              title: filterObject ? filterObject.realName : k,
              section,
              name: filterObject ? filterObject.name : k,
            };
          })
      );
    });
    return result;
  }

  get filtersCount() {
    let count = 0;
    Object.keys(this.currentFilters).forEach((section) => {
      if (["gender", "category_id"].includes(section)) return;
      const current = Object.keys(this.currentFilters[section]).filter(
        (name) => this.currentFilters[section][name]
      );
      count += current.length;
    });
    return count;
  }

  get allowToIndex() {
    let allow = true;
    let fullFiltersCount = 0;
    let fullFiltersLog = [];
    Object.keys(this.currentFilters).forEach((section) => {
      if (["gender", "category_id"].includes(section)) return;
      if (allow) {
        const current = Object.keys(this.currentFilters[section]).filter(
          (name) => this.currentFilters[section][name]
        );
        if (current.length > 1) {
          // console.log("FOUND DOUBLE FILTER", this.currentFilters[section]);
          allow = false;
        }
        fullFiltersCount += current.length;
        if (current.length > 0) {
          fullFiltersLog = [...fullFiltersLog, ...current];
        }
        if (fullFiltersCount >= 3) {
          // console.log("FOUND TRIPPLE FILTER", fullFiltersLog);
          allow = false;
        }
      }
    });
    return allow;
  }

  get remoteFilters() {
    const result = {};
    Object.keys(this.currentFilters).forEach((section) => {
      result[section] = Object.keys(this.currentFilters[section])
        .map((k) => {
          if (!this.currentFilters[section][k]) return false;
          if (section == "category_id" && [48, 50, 49].includes(+k))
            return false;
          const filterObject = getFilterObject(section, k);
          return filterObject ? filterObject.realName : k;
        })
        .filter((a) => a);
    });
    if (this.priceFilter.min > this.minPrice)
      result.priceMin = this.priceFilter.min;
    if (this.priceFilter.max < this.maxPrice)
      result.priceMax = this.priceFilter.max;
    return result;
  }

  constructor() {
    makeAutoObservable(this);
  }

  serialize = () => {
    const values = JSON.stringify({
      filters: this.filters,
    });
    // localStorage.setItem("storages_filters", values);
    return values;
  };

  deserialize = (values, cb) => {
    this.orderby = localStorage.getItem("orderby");
    // let currentValues = values;
    // if (!currentValues) {
    //   currentValues = localStorage.getItem("storages_filters") || "{}";
    //   currentValues = JSON.parse(currentValues);
    // }
    // if (currentValues && currentValues.filters) {
    //   this.filters.map((filter, key) => {
    //     if (currentValues.filters[key]) {
    //       this.filters[key].items = currentValues.filters[key].items;
    //     }
    //   });
    // }
    if (cb) cb(null, true);
  };

  changeOrder = (e) => {
    const { value } = e.target;
    this.orderby = value;
    productsListStorage.currentPage = 0;
    productsListStorage.loadProducts();
    localStorage.setItem("orderby", value);
  };

  loadDictionaries = (inChain, callback) => {
    // if (cb) cb(null, true);
    // return;
    axios.get(`${urls.baseUrl}/api/dictionaries`).then((response) => {
      runInAction(() => {
        this.updateDictionaries(response.data);
        this.setPaymentMethods(response.data.payment);
        this.setDeliveryMethods(response.data.delivery);
        // this.filters.map((filter) => {
        //  if (filter.field && response?.data && response.data[filter.field]) {
        // filter.items = response.data[filter.field]
        //   .map((f) => ({
        //     title:
        //       filter.field === "sizes" && parseInt(f.name, 10) > 0
        //         ? parseInt(f.name, 10)
        //         : f.name,
        //     // name: f.name,
        //     count: f.count,
        //     name: routingStorage.createRoute("slug", { slug: f.name }),
        //   }))
        //   .filter((f) => !!f.title)
        //   .sort((f1, f2) => {
        //     if (f1.title > f2.title) return 1;
        //     if (f1.title < f2.title) return -1;
        //     return 0;
        //   });
        // this.updateDictionaries(response.data);
        // }
        // });
        this.dictionaryLoaded = true;
        if (callback) callback(null, true);
      });
      this.serialize();
      // if (callback) callback(null, true);
    });
  };

  updateDictionaries = (dictionaries) => {
    if (dictionaries) {
      this.filters.map((filter) => {
        if (dictionaries && dictionaries[filter.field]) {
          // eslint-disable-next-line no-param-reassign
          filter.items.forEach((f) => (f.count = 0));
          // filter.items = dictionaries[filter.field]
          dictionaries[filter.field].map((f) => {
            let title = f.name;
            if (filter.field === "sizes") {
              if (f.name.match(/^\d+$/)) {
                title = parseInt(f.name, 10);
              }
            }
            const index = filter.items.findIndex((c) => c.title === title);
            if (index >= 0) {
              filter.items[index].count = f.count;
            } else {
              const attr = attributesStorage[filter.field]
                ? attributesStorage[filter.field][f.name]
                : null;
              const fModel = {
                title,
                // name: f.name,
                count: f.count,
                name: f.name,
                segment: filter.field,
              };
              filter.items.push(new FilterItemModel(fModel));
            }
          });
          filter.items = filter.items
            .filter((f) => !!f.title)
            .sort((f1, f2) => {
              let result = 0;
              if (f1.count > 0 && f2.count === 0) return -1;
              if (f1.count === 0 && f2.count > 0) return 1;
              // if (Number.isInteger(f1.title) && Number.isInteger(f2.title)) {
              //   result = f1.title - f2.title;
              // } else if (
              //   Number.isInteger(f1.title) ||
              //   Number.isInteger(f2.title)
              // ) {
              //   result = parseInt(f1.title, 10) - parseInt(f2.title, 10);
              // } else {
              //   result = f1.title.localeCompare(f2.title);
              // }
              if (filter.field === "sizes" || filter.field === "vgroup") {
                const f1Title = parseInt(f1.title, 10);
                const f2Title = parseInt(f2.title, 10);
                if (
                  (f1Title == f1.title && f2Title == f2.title) ||
                  f1.title.includes("-") ||
                  f2.title.includes("-")
                ) {
                  result = f1Title - f2Title;
                } else {
                  result = f1.title.localeCompare(f2.title);
                }
              } else {
                result = f1.title.localeCompare(f2.title);
              }
              return result;
            });
        }
      });
    }
  };

  setFilter = (group, value) => {
    if (this.currentFilters[group]) {
      if (this.currentFilters[group][value] === true) {
        this.currentFilters[group] = {
          ...this.currentFilters[group],
          [value]: false,
        };
      } else {
        this.currentFilters[group] = {
          ...this.currentFilters[group],
          [value]: true,
        };
      }
      // runInAction(() => {
      //   productsListStorage.loadProducts();
      // });
    }
  };

  setFilters = (filters) => {
    this.emptyFilters(filters);
    // this.currentFilters = { ...this.currentFilters, ...filters };
  };

  setPaymentMethods = (methods) => {
    if (methods) {
      orderStorage.paymentTypes = [];
      methods.forEach((method) => {
        const newMethod = new PaymentMethodModel(method);
        orderStorage.paymentTypes.push(newMethod);
      });
    }
  };

  setDeliveryMethods = (methods) => {
    if (methods) {
      orderStorage.deliveryTypes = [];
      methods.forEach((method) => {
        const newMethod = new DeliveryMethodModel(method);
        orderStorage.deliveryTypes.push(newMethod);
      });
    }
  };

  setColors = (colorsMap) => {
    this.colorsMap = colorsMap;
  };

  setAllFiltersOpened = (expanded) => {
    if (expanded !== undefined) {
      this.filters.forEach((filter) => (filter.opened = expanded));
    }
  };

  changePriceFilter = (values) => {
    this.priceFilter.min = values[0];
    this.priceFilter.max = values[1];
  };
}

const filtersStorage = new FiltersStorage();

export default filtersStorage;
