import { makeAutoObservable } from "mobx";
import axios from "axios";
import { toast } from "react-toastify";
import urls from "../api/urls";
import Core from "../../core/Core";

class ContentPageModel {
  header = {
    title: "Title",
    description: "Description",
  };

  content = {
    id: null,
    title: null,
    short: null,
    pubDate: null,
    author: null,
    mainImage: null,
    altText: null,
    tags: null, // [{ name: "Vintage", link: "/content/tags/vintage" }],
    prevPost: null, // { title: "Previous", link: "/content" },
    nextPost: null, // { title: "Next", link: "/content" },
    content: null,
    link: null,
    slug: null,
    type: null,
  };

  constructor(structure) {
    if (structure) {
      this.content.id = structure.ID;
      this.content.content = structure.text;
      this.content.title = structure.title;
      this.content.type = structure.type;
      this.content.mainImage = structure.image;
      this.content.pubDate = structure.published_at;
      this.content.slug = structure.slug;
      this.content.link = Core.routing.createRoute("content", {
        slug: structure.slug,
      });
    }
    makeAutoObservable(this);
  }

  changeText = (text) => {
    // console.log(text);
    this.content.content = text;
  };

  changeTitle = (text) => (this.content.title = text);

  saveContent = () => {
    axios
      .put(
        `${urls.baseUrl}/super/api/posts/${this.content.id}`,
        {
          ID: this.content.id,
          image: this.content.mainImage,
          published_at: this.content.pubDate,
          slug: this.content.slug,
          text: this.content.content,
          title: this.content.title,
          type: this.content.type,
          value: 0,
        },
        {
          withCredentials: true,
        }
      )
      .then((resp) => {
        // console.log(resp);
        toast.success("Сохранено");
      });
  };
}

export default ContentPageModel;
