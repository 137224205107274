import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { toast } from "react-toastify";
import MenuModel from "../models/MenuModel";
import urls from "../api/urls";
import PromoboxModel from "../models/PromoboxModel";

class HomePageStorage {
  heroEdit = false;

  promoBoxEdit = false;

  homeCatsEdit = false;

  textShadowEdit = false;

  textStrokeEdit = false;

  dateAndTimeOfPromotionEdit = false;

  // timeOfPromotionEdit = false;

  allowHomePromotions = false;

  allowFeaturedProducts = false;

  hero = [
    {
      mainImage: null,
      alternativeImage: null,
      imageAlt: null,
      texts: [],
      buttons: [],
      disableButton: false,
      shadowColor: "",
      textStroke: "",
      textStrokeWidth: "",
      shadowDistance: "4",
      shadowBlur: "4",
      dateOfPromotion: null,
      allowTextShadow: false,
      allowTextStroke: false,
      allowDateAndTimeOfPromotion: false,
      enabled: true,
      outlineType: "",
      allowLeftOnMobile: false,
    },
  ];

  changedHero = false;

  featuredCategories = [{}, {}, {}];

  productsTabs = [
    { title: "New In", listField: "newProductsList", limit: 8, enabled: true },
    {
      title: "Sale & Special Offers",
      listField: "specialProductsList",
      limit: 8,
      enabled: true,
    },
    {
      title: "Featured Products",
      listField: "featuredProductsList",
      limit: 8,
      enabled: true,
    },
  ];

  collecttions = [];

  trendingProducts = [];

  brands = [];

  blogPosts = [];

  instagramPosts = {
    title: "@Shella Follow Us On Instagram",
  };

  promotions = [];

  heroSlider = {
    speed: 1000,
    autoplaySpeed: 3000,
    allowAutoplay: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setDateOfPromotion = (index, date) => {
    this.heroEdit = true;
    this.hero[index].dateOfPromotion = date;
  };

  setHero = (data) => {
    if (data) {
      this.hero = [];
      data.forEach((content) => {
        console.log("WREST", content, content.allowDateOfPromotion);
        this.hero.push({
          mainImage: content.mainImage,
          alternativeImage: content.alternativeImage,
          imageAlt: content.imageAlt,
          texts: content.texts,
          buttons: content.buttons.map((b) => new MenuModel(b)),
          shadowColor: content.shadowColor || "#AABBCC",
          textStroke: content.textStroke || "#AABBCC",
          shadowDistance: content.shadowDistance || "4",
          shadowBlur: content.shadowBlur || "4",
          dateOfPromotion: content.dateOfPromotion
            ? new Date(content.dateOfPromotion)
            : new Date(),
          allowTextShadow: content.allowTextShadow || false,
          allowTextStroke: content.allowTextStroke || false,
          allowDateAndTimeOfPromotion:
            content.allowDateAndTimeOfPromotion || false,
          textStrokeWidth: content.textStrokeWidth || "",
          enabled: content.enabled === undefined ? false : content.enabled,
          outlineType: content.outlineType,
          allowLeftOnMobile: !!content.allowLeftOnMobile,
        });
      });
    }
  };

  addHero = () => {
    this.hero.push({
      mainImage: null,
      alternativeImage: null,
      imageAlt: null,
      texts: [],
      buttons: [null, null].map((b) => new MenuModel(b)),
      disableButton: false,
      shadowColor: "",
      shadowDistance: "4",
      shadowBlur: "4",
      dateOfPromotion: "", // moment().format("YYYY MMM DD"),
      timeOfPromotion: "", // moment().format("h:mm:ss"),
      allowTextShadow: false,
      allowTextStroke: false,
      allowDateOfPromotion: false,
      allowTimeOfPromotion: false,
      textStrokeWidth: "",
      enabled: false,
      outlineType: "textShadow",
      allowLeftOnMobile: false,
    });
  };

  setPromos = (data) => {
    if (data) {
      this.promotions = [];
      data.forEach((content) => {
        const promo = new PromoboxModel(content);
        this.promotions.push(promo);
      });
    }
  };

  setFeaturedCategories = (categories) => {
    this.featuredCategories = [];
    categories.forEach((cat) => {
      this.featuredCategories.push({
        ...cat,
        ...new MenuModel(cat),
        menu: cat.menu.map((m) => new MenuModel(m)),
      });
    });
  };

  changeState = (state, value) => {
    if (this[state] !== undefined) this[state] = value;
  };

  changeHeroText = (index, textIndex, text) => {
    this.changedHero = true;
    this.hero[index].texts[textIndex] = text;
  };

  toggleTextShadowEdit = () => {
    this.textShadowEdit = !this.textShadowEdit;
  };

  toggleTextStrokeEdit = () => {
    this.textStrokeEdit = !this.textStrokeEdit;
  };

  toggleDateAndTimeOfPromotionEdit = () => {
    this.dateAndTimeOfPromotionEdit = !this.dateAndTimeOfPromotionEdit;
  };

  changeAllowTextShadow = (index) => {
    this.changedHero = true;
    this.hero[index].allowTextShadow = !this.hero[index].allowTextShadow;
  };

  changeAllowTextStroke = (index) => {
    this.changedHero = true;
    this.hero[index].allowTextStroke = !this.hero[index].allowTextStroke;
  };

  changeAllowDateAndTimeOfPromotion = (index) => {
    this.changedHero = true;
    this.hero[index].allowDateAndTimeOfPromotion =
      !this.hero[index].allowDateAndTimeOfPromotion;
  };

  // changeAllowTimeOfPromotion = (index) => {
  //   this.changedHero = true;
  //   this.hero[index].allowTimeOfPromotion =
  //     !this.hero[index].allowTimeOfPromotion;
  // };

  changeEnabledSlide = (index) => {
    this.changedHero = true;
    this.hero[index].enabled = !this.hero[index].enabled;
  };

  changeShadowColor = (index, shadowColor) => {
    this.changedHero = true;
    this.hero[index].shadowColor = shadowColor;
  };

  changeTextStroke = (index, textStroke) => {
    this.changedHero = true;
    this.hero[index].textStroke = textStroke;
  };

  changeShadowDistance = (index, shadowDistance) => {
    this.changedHero = true;
    this.hero[index].shadowDistance = shadowDistance;
  };

  changeShadowBlur = (index, shadowBlur) => {
    this.changedHero = true;
    this.hero[index].shadowBlur = shadowBlur;
  };

  changeDateOfPromotion = (index, dateOfPromotion) => {
    this.changedHero = true;
    this.hero[index].dateOfPromotion = dateOfPromotion;
  };

  changeTimeOfPromotion = (index, timeOfPromotion) => {
    this.changedHero = true;
    this.hero[index].timeOfPromotion = timeOfPromotion;
  };

  changeTextStrokeWidth = (index, textStrokeWidth) => {
    this.changedHero = true;
    this.hero[index].textStrokeWidth = textStrokeWidth;
  };

  changeOutlineType = (index, outlineType) => {
    this.changedHero = true;
    this.hero[index].outlineType = outlineType;
  };

  changeHeroButtonSegment = (index, buttonIndex, segment) => {
    this.changedHero = true;
    this.hero[index].buttons[buttonIndex].segment = segment;
  };

  changeHeroButtonSlug = (index, buttonIndex, slug) => {
    this.changedHero = true;
    this.hero[index].buttons[buttonIndex].slug = slug;
  };

  changeHeroButtonLink = (index, buttonIndex, link) => {
    this.changedHero = true;
    this.hero[index].buttons[buttonIndex].link = link;
  };

  changeEnabledButton = (index, buttonIndex) => {
    this.changedHero = true;
    this.hero[index].buttons[buttonIndex].enabled =
      !this.hero[index].buttons[buttonIndex].enabled;
  };

  changeHeroAlt = (index, text) => {
    this.changedHero = true;
    this.hero[index].imageAlt = text;
  };

  changeHeroButtonTitle = (index, buttonIndex, title) => {
    this.changedHero = true;
    // console.log("HERO", this.hero[index].buttons, index, buttonIndex);
    this.hero[index].buttons[buttonIndex].title = title;
  };

  uploadHeroImage = (index, file, id) => {
    // console.log(file);
    const url = `${urls.baseUrl}/api/v1/images/upload`;
    const data = new FormData();
    data.append("image", file);
    data.append("segment", "hero");
    axios.post(url, data).then((resp) => {
      runInAction(() => {
        this.hero[index].mainImage = resp.data.url;
      });
    });
  };

  uploadFeaturedCategoryImage = (index, file, id) => {
    // console.log(file);
    const url = `${urls.baseUrl}/api/v1/images/upload`;
    const data = new FormData();
    data.append("image", file);
    data.append("segment", "featured");
    axios.post(url, data).then((resp) => {
      runInAction(() => {
        this.featuredCategories[index].image = resp.data.url;
      });
    });
  };

  uploadHeroAlternativeImage = (index, file, id) => {
    // console.log(file);
    const url = `${urls.baseUrl}/api/v1/images/upload`;
    const data = new FormData();
    data.append("image", file);
    data.append("segment", "hero");
    axios.post(url, data).then((resp) => {
      runInAction(() => {
        this.hero[index].alternativeImage = resp.data.url;
      });
    });
  };

  uploadPromoImage = (index, file, id) => {
    // console.log(file);
    const url = `${urls.baseUrl}/api/v1/images/upload`;
    const data = new FormData();
    data.append("image", file);
    data.append("segment", "promo");
    axios.post(url, data).then((resp) => {
      runInAction(() => {
        this.promotions[index].mainImage = resp.data.url;
      });
    });
  };

  saveHeros = () => {
    axios
      .put(`${urls.baseUrl}/super/api/structure`, {
        heros: this.hero,
        herosSliderConfig: this.heroSlider,
      })
      .then((resp) => {
        toast.success("Сохранено");
      });
  };

  saveFeaturedCategories = () => {
    axios
      .put(`${urls.baseUrl}/super/api/structure`, {
        featuredCategories: this.featuredCategories,
      })
      .then((resp) => {
        toast.success("Сохранено");
      });
  };

  addPromo = () => this.promotions.push(new PromoboxModel());

  removePromo = (index) => this.promotions.splice(index, 1);

  savePromos = () => {
    axios
      .put(`${urls.baseUrl}/super/api/structure`, {
        promo: this.promotions,
      })
      .then((resp) => {
        toast.success("Сохранено");
      });
  };

  saveMiddleCats = () => {
    axios
      .put(`${urls.baseUrl}/super/api/structure`, {
        featuredCategories: this.featuredCategories,
      })
      .then((resp) => {
        console.log("HERO SAVE", resp);
      });
  };

  changePromoText = (index, textIndex, text) => {
    this.promotions[index].texts[textIndex] = text;
  };

  changePromoLink = (index, text) => {
    this.promotions[index].link = text;
  };

  changePromoLinkText = (index, text) => {
    this.promotions[index].linkText = text;
  };

  removeHero = (index) => {
    this.hero.splice(index, 1);
  };

  changeAllowLeftOnMobile(index) {
    this.hero[index].allowLeftOnMobile = !this.hero[index].allowLeftOnMobile;
  }

  changeHeroSlideConfig = (fieldName, value) => {
    this.heroSlider[fieldName] = value;
  };

  setHerosSliderConfig = (herosSliderConfig) => {
    if (herosSliderConfig) {
      if (herosSliderConfig.allowAutoplay !== undefined)
        this.heroSlider.allowAutoplay = herosSliderConfig.allowAutoplay;
      if (herosSliderConfig.speed !== undefined)
        this.heroSlider.speed = +herosSliderConfig.speed;
      if (herosSliderConfig.autoplaySpeed !== undefined)
        this.heroSlider.autoplaySpeed = +herosSliderConfig.autoplaySpeed;
    }
  };

  toggleHomePromotions = () => {
    this.allowHomePromotions = !this.allowHomePromotions;
  };

  toggleFeaturedProducts = () => {
    this.allowFeaturedProducts = !this.allowFeaturedProducts;
  };

  addFeaturedCategoryItem = (index) => {
    this.featuredCategories[index].menu.push(new MenuModel());
  };

  removeFeaturedCategoryItem = (categoryIndex, index) =>
    this.featuredCategories[categoryIndex].menu.splice(index, 1);

  changeFeaturedCategoryField = (fieldName, categoryIndex, text) => {
    this.featuredCategories[categoryIndex][fieldName] = text;
  };
}

const homePageStorage = new HomePageStorage();

export default homePageStorage;
