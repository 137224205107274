import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import UserOrderModel from "./models/UserOrderModel";
import urls from "./api/urls";
import layoutStorage from "./layoutStorage";

class OrdersStorage {
  orders = {};

  ordersList = [];

  paymentWidgets = {};

  currentPage = 0;

  pageSize = 10;

  orderStatuses = {
    ru: {
      0: "создан",
      1: "оплачен",
      2: "ожидает оплаты",
      3: "ожидает подтверждения",
      4: "ожидает подтверждения",
      5: "ошибка оплаты",
      7: "платеж возвращен",
      8: "прочее",
      9: "ожидает пользователя",
      10: "неизвестно",
      11: "готовится к отправке",
      12: "отправлен",
      13: "получен",
      14: "требует уточнения",
      15: "отменен",
      16: "отменен",
      17: "ошибка",
      120: "зарезервирован",
    },
    uk: {
      0: "створено",
      1: "сплачено",
      2: "чекає сплати",
      3: "чекає підтвердження банком",
      4: "чекає підтвердження менеджером",
      5: "помилка сплати",
      7: "платіж повернуто",
      8: "інше",
      9: "чекає дії користувача",
      10: "невідомий статус",
      11: "готується до відправки",
      12: "відправлено клієнту",
      13: "отримано клієнтом",
      14: "потрубує уточнення деталей",
      15: "відмінене клієнтом",
      16: "відмінене менеджером",
      17: "помилка замовлення",
      120: "зарезервовано",
    },
  };

  constructor() {
    makeAutoObservable(this);
  }

  get ordersListPage() {
    const result = this.ordersList.slice(
      this.currentPage * this.pageSize,
      (this.currentPage + 1) * this.pageSize
    );
    return result;
  }

  get isFirstPage() {
    return this.currentPage === 0;
  }

  get isLastPage() {
    return (
      this.currentPage >= Math.ceil(this.ordersList.length / this.pageSize)
    );
  }

  get haveMorePages() {
    return (this.currentPage + 1) * this.pageSize < this.ordersList.length;
  }

  loadOrders = (arg, cb) => {
    axios.get(`${urls.baseUrl}/api/users/current/orders`).then((resp) => {
      // console.log("ORDERS: ", resp?.data);
      const orders = resp?.data?.orders;
      if (orders && orders.length > 0) {
        runInAction(() => {
          this.orders = {};
          this.ordersList = [];
          orders.forEach((order) => {
            const currentOrder = new UserOrderModel(order);
            const orderID = `${currentOrder.userID}-${currentOrder.orderID}`;
            this.orders[orderID] = currentOrder;
            this.ordersList.push(orderID);
          });
        });
        if (cb) cb(null, true);
      }
    });
  };

  getPaymentWidget = (orderId) => {
    const returnUrl = `https://${window.location.hostname}/${layoutStorage.currentLanguage}/orders/status/${orderId}`;
    axios
      .get(`${urls.baseUrl}/api/v7/orders/paymentWidget/${orderId}`, {
        params: {
          returnUrl,
        },
      })
      .then((resp) => {
        const paymentWidget = resp?.data?.paymentWidget;
        const order = resp?.data?.order;
        if (paymentWidget) {
          runInAction(() => {
            this.paymentWidgets[orderId] = paymentWidget;
            const currentOrder = new UserOrderModel(order);
            const orderID = `${currentOrder.userID}-${currentOrder.orderID}`;
            this.orders[orderID] = currentOrder;
            this.ordersList.push(orderID);
          });
        }
      });
  };

  previousPage = () => {
    if (this.currentPage > 0) {
      this.currentPage -= 1;
    }
  };

  nextPage = () => {
    if (this.currentPage < this.ordersListPage.length - 2) {
      this.currentPage += 1;
    }
  };

  firstPage = () => {
    this.currentPage = 0;
  };

  lastPage = () => {
    this.currentPage = Math.ceil(this.ordersList.length / this.pageSize - 1);
  };
}

const ordersStorage = new OrdersStorage();

export default ordersStorage;
